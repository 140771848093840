import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import { EventEmitter,Inject,Injectable,NgZone,  PLATFORM_ID} from '@angular/core';
import { Router } from '@angular/router';
import { Buffer } from 'buffer';
import { DeviceDetectorService, DeviceType } from 'ngx-device-detector';
import { IConstants } from '../models/constants';
import { GlobalEmptyImgTheme, ManifestTypes, UserRole, getdeviceType, smallScreenMobileSize } from '../models/enum';
import { GlobalSettings } from 'src/app/global.settings';
import { BehaviorSubject, catchError,delay,first,fromEvent, interval, map, Observable, of, share, Subject,Subscription,switchMap,tap} from 'rxjs';
import { PageChange } from '../models/canvasData';
import { IpcService } from './ipc.service';
import { LoggerService } from './logger.service';
import { Data } from 'src/app/core/models/data';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { ApiConstants } from 'src/app/core/constant/api.constant';
import { NotificationService } from './notifications.service';
import { getMessaging, getToken } from 'firebase/messaging';
import { isPlatformBrowser } from '@angular/common';
import { Base64 } from 'js-base64';
import { CourseService } from './course.service';
import { environment } from "src/environments/environment";
import { UtilityService } from 'src/app/services/utility.service';
import { MockuserTheme, mockthemeDetails } from 'src/app/shared/global-navigation/global-navigation.component.mock';
import { BookInteractions, GTMEvents } from '../../core/models/gtm-models/gtm';
import { GtmService } from './gtm.service';

const { detect } = require('detect-browser');
const browser = detect();

@Injectable({
  providedIn: 'root'
})

export class CommonService {
  baseUrl: string = ApiConstants.aceApiBaseUrl;
  public url: any;
  updateDom$!: Observable<any>;
  private updateDomSubject = new BehaviorSubject<any>(false);

  public globalDekstopOfflineModePopUp$ = new BehaviorSubject<any>(false);
  public globalDesktopOfflinePage$ =new BehaviorSubject<any>(false);
  public globalSystemErrorPage$ =new BehaviorSubject<any>({});
  public globalSystemErrorPageNative$: EventEmitter<any> = new EventEmitter();
  public studentSubsriptionData$ =new BehaviorSubject<Map<string,any>>(null);
  public displayReaderPage$=new BehaviorSubject (true);
  private pageChange = new Subject<any>();
  public currentSubscription: any = null;
  pageChangeObservable$ = this.pageChange.asObservable();
  lessonExtraActivity$!: Observable<any>;
  private callLessonExtraActivitySubject = new BehaviorSubject<any>(false);
  public hotcorner$ = new BehaviorSubject<any>(false);

  editExistingBookmark: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);  
  public globalDekstopOfflineModeLoadTOC$ = new BehaviorSubject<any>(false);
  fcmCurrentToken :any ='';
  subscriptions: Subscription[] = [];
  public isPlatformBrowser = true;
  migratedPagesData: any;
  private destroy$: Subject<boolean> = new Subject();
  public utilityService!:UtilityService;
  private worker: Worker | null = null;
  public isLanguageDataUpadated : boolean = false;
  
  constructor(public data: Data,private logger:LoggerService, 
    private router: Router, private ngZone:NgZone,
    public deviceService: DeviceDetectorService,
    public http:HttpClient,public constants: IConstants,
    public notificationService: NotificationService,
    @Inject(PLATFORM_ID) public platformId: Object,
    public cookieService: SsrCookieService,
    public courseService:CourseService,
    public gtmService: GtmService,
  ) {
    this.updateDom$ = this.updateDomSubject.asObservable();
    this.lessonExtraActivity$ = this.callLessonExtraActivitySubject.asObservable();
  }

  convertToBase64(obj: string): string {
    return Buffer.from(obj).toString('base64');
  }

  convertToAnnotationData(str: string): string {
    return Buffer.from(str, 'base64').toString();
  }

  public pageChanged(data: PageChange) {
    if(data) {
      this.pageChange.next(data);
    }
  }

  isLoading_show() {this.data.isLoading.emit(true)}
  isLoading_hide() {this.data.isLoading.emit(false)}
  openExternalURLCallback(externalUrl: string, isExternalBrowser?: boolean) {
    let data: any;
    if(isExternalBrowser) {
      data = {
        url: externalUrl,
        isExternal: true,
        isExternalBrowser: isExternalBrowser
      };
    } else {
      data = {
        url: externalUrl,
        isExternal: true
      };
    }
    openExternalURLCallback(data, null);

  }

  getTheam(): any {
    return this.getOnlineThemes();
  }

  browserGetThemeDetails():any {
    const url = this.baseUrl + ApiConstants.getThemeListUrl;
    return this.http.get<any>(url).pipe(tap(response => {
    return response;
  }));
  }

  nativeGetThemeDetailsCallback():any {
    return new Observable(observer => {
      getThemesCallback(this.bindNativeThemesObservable(observer));
    });
  }

  bindNativeThemesObservable(observer: any) {
    return (res: any) => {
      res = JSON.parse(res);
      if (res.error) {
        observer.next(res.error);
      } else {
        observer.next(res.result);
      }
    };
  }

  desktopGetThemeDetails() {
    this.url= GlobalSettings.LocalNodeServerBaseUrl + '/api/coreplatform/getThemes';
    return this.http.get<any>(this.url)
      .pipe(
            tap(response => response),
            catchError(this.handleAPIError('desktopGetThemeDetails', []))
          );
  }


  getOnlineThemes():any {
    if (GlobalSettings?.isBrowser) {
      return this.browserGetThemeDetails();
    } else if (GlobalSettings?.isNative) {
      return this.nativeGetThemeDetailsCallback();
    } else {
      return this.desktopGetThemeDetails();
    }
  }

  getUserSelectedTheme():any {
    if (GlobalSettings?.isBrowser) {
      return this.browserCurrentThemeDetails();
    } else if (GlobalSettings?.isNative) {
      return this.nativeCurrentThemeDetailsCallback();
    } else {
      return this.desktopCurrentThemeDetails();
    }
  }

  browserCurrentThemeDetails() {
    const url = this.baseUrl + ApiConstants.getcurrentusertheme;
    return this.http.get<any>(url).pipe(tap(response => {
    return response;
    }));
  }

  nativeCurrentThemeDetailsCallback() {
    return new Observable(observer => {
      getCurrentThemeCallback(this.bindNativeThemesObservable(observer));
    });
  }

  desktopCurrentThemeDetails() {
    this.url= GlobalSettings.LocalNodeServerBaseUrl + '/api/coreplatform/getCurrentUserTheme';
    return this.http.get<any>(this.url)
      .pipe(
      tap(response => response),
      catchError(this.handleAPIError('desktopGetThemeDetails', []))
    );
  }

  themeChangedByUser(themeId:any) {
    if (this.data?.meeToken && this.data?.aceToken && themeId) {
      if (GlobalSettings?.isBrowser) {
        return this.browserThemeChangedByUser(themeId);
    } else if (GlobalSettings?.isNative) {
        return this.nativeThemeChangedByUserCallback(themeId);
      } else {
        return this.desktopThemeChangedByUser(themeId);
      }
    } 
  }

  browserThemeChangedByUser(themeId:any):Observable<any> {
    this.url = this.baseUrl + ApiConstants.getEditprofile;
    let cookie_key = this.cookieService.get('token_key');
    let reqBody:any=  { 
      email: this.data.meeToken.email,
      userTheme: {
        userId: this.data.aceToken?.MEEUserId ,
        themeId: themeId
      }
    }
    let headers = new HttpHeaders({
      "cookiekey" : cookie_key
    });
    return this.http.post(this.url, reqBody, { headers })
      .pipe(tap(response => response),
    );
  }

  nativeThemeChangedByUserCallback(themeId:any) {
    let cookie_key = this.cookieService.get('token_key');
    let reqBody:any=  { 
      email: this.data.meeToken.email,
      userTheme: {
        userId: this.data.aceToken?.MEEUserId ,
        themeId: themeId
      }
    }
    let headers = new HttpHeaders({
      "cookiekey" : cookie_key
    });
    return new Observable(observer => {
      getEditProfileCallback(reqBody,this.bindNativeThemesObservable(observer));
    });
  }



  desktopThemeChangedByUser(themeId:any) {
    this.url= GlobalSettings.LocalNodeServerBaseUrl + '/api/coreplatform/editProfile';
    let cookie_key = this.cookieService.get('token_key');
    let reqBody:any=  { 
      email: this.data.meeToken.email,
      userTheme: {
        userId: this.data.aceToken?.MEEUserId ,
        themeId: themeId
      }
    }
    let headers = new HttpHeaders({
      "cookiekey" : cookie_key
    });
    return this.http.post(this.url, reqBody, { headers })
      .pipe(tap(response => response),
    );
  }

  loadJSON(langType: any = 'en'): Observable<any> {
    if (this.data.languageJson.languageType && this.data.languageJson.languageType == langType && this.isLanguageDataUpadated) {
      return of(this.data.languageJson);
    } else {
      this.isLanguageDataUpadated = true;
      if (!langType || langType == '' || langType == undefined || langType == 'undefined') {
        langType = GlobalSettings.defultLang;
      }
      let baseUrl = new URL(ApiConstants.languageCdnUrl);
      return this.http.get<any>(baseUrl + '/meeLanguageTranslation_' + langType + '.json').pipe(
        catchError(error => {
          let localBaseUrl = new URL(ApiConstants.siteUrl).origin;
          return this.http.get<any>(localBaseUrl + '/assets/language/meeLanguageTranslation_en.json').pipe(
            catchError(localError => {
              return of([]);
            })
          );
        })
      );
    }
  }
  loadNewLanguagesInDevice(langArr: any) {
    const skipLangType = "en";
    if (GlobalSettings.isNative) {
      let url = 'useruploadedfiles/languagefiles/meeplatform/';
      let arr = langArr
      .filter(val => val.value !== skipLangType)
      .map((val: any) => {
        return url + 'meeLanguageTranslation_' + val.value + '.json';
      });
      downloadLanguagesCallback(arr, (res: any) => {
        console.log('downloadLanguagesCallback success response:', res);
        res = JSON.parse(res);
      });
    } else if (GlobalSettings.isDesktop) {
      let nodeApiUrl = GlobalSettings.LocalNodeServerBaseUrl + '/api/download/downloadLanguageData';
      let fileName = 'meeLanguageTranslation';
      let arr = langArr
      .filter(val => val.value !== skipLangType)
      .map((val: any) => {
        return fileName + '_' + val.value + '.json';
      });
      this.http
        .post(nodeApiUrl, {fileUrl: arr, env: environment.name })
        .subscribe((data) => {
          console.log('desktop app response:', data);
        });
    }
  }
  removeSpecialCharsFromToc(contentUnitId:string,manifestType:string){
    if (this.deviceService.os == this.constants.platformAndroid && manifestType == ManifestTypes.TOC) {
        contentUnitId=decodeURIComponent(contentUnitId);
        contentUnitId = contentUnitId.replace(/[^a-zA-Z0-9 ]/g, '');
        contentUnitId=encodeURIComponent(contentUnitId);
    }
    return contentUnitId;
  }

  setMigratedPagesData(data: any) {
    this.migratedPagesData = data;
  }
  getMigratedPagesData() {
    return this.migratedPagesData;
  }

  handleError(error: HttpErrorResponse) {
    if (error.error && error.error.message) {
      this.logger.logError( error.error.message);
    } else {
      this.logger.logError(`Backend returned code ${error.status} body was: ${error.error}`);
    }
  }

  delay(ms: number)
  {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

convertBytes(bytes:any){

  let l = 0, n = parseInt(bytes, 10) || 0;

  while(n >= 1024 && ++l){
      n = n/1024;
  }
  //include a decimal point and a tenths-place digit if presenting
  //less than ten of KB or greater units
  return(n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + this.constants.units[l]);
}
  openExternalLink(externalLinkData: any, navigationUrl?: string){
    let data = {
      url: externalLinkData.url,
      isExternal: true
    };

    if (GlobalSettings.isNative) {
      openExternalURLCallback(data,null);
    }
    else if(GlobalSettings.isDesktop) {
      new IpcService().send('openExternalLink', externalLinkData.url);
    }
    else {
      window.open(externalLinkData.url, externalLinkData.target);
    }

    if(navigationUrl) {
      this.ngZone.run(() => this.router.navigateByUrl(navigationUrl));
    }
  }
  setUpdate(data:any){
    this.updateDomSubject.next({isVisible:data});
  }
  setLessonExtraActivity(data:any){
    this.callLessonExtraActivitySubject.next({activity:data});
  }
  //## For captutre device type
  getDeviceType(){
    const ua = navigator.userAgent;
    const isIpad = /Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1;  
         
    if ((/(tablet|iPad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) || isIpad) {
      if(this.data.iwbModeBtn){
        return "desktop";
      }else{
        return "tablet";
      }
    }
    if (
      /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      if(this.data.iwbModeBtn){
        return "desktop";
      }else{
        return "mobile";
      }
    }
    return "desktop";
  }
  
  gNavigation(menuList:any){   
    this.data.globalNavigationMenuListStatic = menuList;
    this.data.globalNavigationMenuList.emit(menuList);
  }

  saveToken(params: any): Observable<any> {
    if (this.data.saveTokenHappen) return of([]);
    this.data.saveTokenHappen = true;
    if (GlobalSettings?.isBrowser) {
      return this.browserSaveUpdateUserDeviceTokenDetails(params);
    } else if (GlobalSettings?.isNative) {
      return this.nativeSaveUpdateUserDeviceTokenDetailsCallback(params);
    } else {
      return this.desktopSaveUpdateUserDeviceTokenDetails(params);
    }
  }

  getNameById() {
    if (this.data?.defaultTheme && this.data?.themeList && !this.data.isOfflineUser) {
      this.data.userSelectedThemeId = (this.data?.userSelectedThemeId === '') ? this.data?.defaultTheme[0]?.id : this.data?.userSelectedThemeId;
      const theme = this.data.themeList.find((theme:any) => theme.id === this.data.userSelectedThemeId);
      let selectedThemeData =  theme ? theme.class : GlobalSettings.defaultTheme;
      this.data.isDataThemeSet.next(true);
      this.data.userSelectedThemeClass = selectedThemeData;
      if (isPlatformBrowser(this.platformId)) {
        const htmlElement = document.querySelector('html');
        if (htmlElement && selectedThemeData) {
          htmlElement.setAttribute('data-theme', selectedThemeData);
        }
      } else {
        this.data.isUserSelectedThemeClass.next(this.data.userSelectedThemeClass);
      }
      this.data.globalEmptyImgErrorTheme.next(GlobalEmptyImgTheme[this.data.userSelectedThemeClass]);
    }
  }

  setDefaultThemeClass() {
    const htmlElement = document.querySelector('html');
    if (htmlElement && this.data.userSelectedThemeClass) {
      this.data.userSelectedThemeClass = GlobalSettings.defaultTheme;
      htmlElement.setAttribute('data-theme', this.data.userSelectedThemeClass);
    }
  }

  getUniqueThemeCategories() {
    let arrangedArray = [];
    if (this.data.themeList && this.data.globalNavigationMenuListStatic && this.data.globalNavigationMenuListStatic.length > 0) {
      let findchildren = this.data.globalNavigationMenuListStatic.filter((filter:any)=> filter.id === 'theme')     
      this.data.defaultTheme = this.data.themeList.filter((theme:any) => theme.isDefault == true);
      if (findchildren.length > 0  && findchildren[0]?.children && findchildren[0]?.children?.length <=2) {
          for (const child of findchildren[0]?.children) {
            if (this.data.themeList) {
              const matchingThemes = this.data.themeList.filter((theme:any) => theme.themeType === child.name);
              arrangedArray.push(child);
              arrangedArray.push(...matchingThemes);
              findchildren[0].children = arrangedArray;
            }
          }
        }
    }
  }

  browserSaveUpdateUserDeviceTokenDetails(params: any): Observable<any> {
    this.url = this.baseUrl + ApiConstants.saveDeviceToken;
    return this.http.post(this.url, { userDeviceToken: params.token})
      .pipe(
            tap(response => response),
            catchError(this.handleAPIError('browserSaveUpdateUserDeviceTokenDetails', []))
      );
  }

  nativeSaveUpdateUserDeviceTokenDetailsCallback(params: any): Observable<any> {
    return new Observable(observer => {
      saveUpdateUserDeviceTokenDetailsCallback(((res: any) => {
        console.log("calling callback native");
      }));
    });
  }

  desktopSaveUpdateUserDeviceTokenDetails(params: any): Observable<any> {
    this.url= GlobalSettings.LocalNodeServerBaseUrl + '/api/notifications/saveUpdateUserDeviceTokenDetails';
    return this.http.post<any>(this.url,{userDeviceToken: params.token})
      .pipe(
            tap(response => response),
            catchError(this.handleAPIError('saveUpdateUserDeviceTokenDetails', []))
          );
  }

  handleAPIError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      this.logger.logInfo(error);
      return of(result as T);
    };
  }
  badgeCount() {
    // observable is subscribed to only once, and the result is shared among all subscribers. This can improve performance
    const notificationsCount$ = this.notificationService.getNotificationsCount().pipe(
      map(data => data.reduce((total: number, notification: any) => total + notification.notificationsCount, 0)),
      share() // Share the result to avoid multiple subscriptions
    );
    notificationsCount$.subscribe(badgeCount => {
      const menuList = this.data.globalNavigationMenuListStatic;
      const badgeIndex = menuList.findIndex((item: any) => item.id === 'Notifications');
      if (badgeIndex !== -1) {
        menuList[badgeIndex].badge = badgeCount;
      }
      this.gNavigation(menuList);
    });
  }
  
  getuserslatestnotifications(): Observable<any> {
    const intervalTime =  GlobalSettings.intervalTime * 20000;
    const url = this.baseUrl + ApiConstants.getuserslatestnotifications;
    return interval(intervalTime).pipe(
      switchMap(() => {
        if (!this.data.aceToken?.Role) {
          return of([]);
        }
        return this.http.post<any>(url, this.formatDate());
      }),
      tap(response => {
        return response;
      }),
      catchError(error => {
        return of([]);
      })
    ); 
  }
  formatDate(){
    let currentDate = new Date();
    currentDate.setMinutes(currentDate.getMinutes() - GlobalSettings.intervalTime);
    const utcDate = new Date(Date.UTC(
      currentDate.getUTCFullYear(),
      currentDate.getUTCMonth(),
      currentDate.getUTCDate(),
      currentDate.getUTCHours(),
      currentDate.getUTCMinutes(),
      currentDate.getUTCSeconds(),
      currentDate.getUTCMilliseconds()
    ));
    const formatDte = utcDate.toISOString();
    return { "fromDateTime": formatDte};
  }
  getSchoolWorkMenuItems(isCurriculumEreaderUser:boolean){
    let modifiedMenuList = JSON.parse(JSON.stringify(this.data.globalNavigationMenuListStatic));
    
    //Hiding grading menu for student role or curriculum Ereader users. 
    if (this.data.aceToken?.Role.toLocaleLowerCase() === UserRole.Student.toLocaleLowerCase() || isCurriculumEreaderUser) {
      const adminClassObject = modifiedMenuList.find((item: { id: string; }) => item.id === 'adminCalss');
      if (adminClassObject && this.data.aceToken?.Role.toLocaleLowerCase() === 'student') {
          adminClassObject.isHide = 'true';
      }
      modifiedMenuList.forEach((menuObj: any) => {
        if (menuObj.id === 'School' && menuObj.children instanceof Array) {
          menuObj.children.forEach((submenu: any, index: number) => {
            if(submenu.id === 'schoolGrading') {
              menuObj.children.splice(index, 1);
            }
          });
        }
      });
    }
    //Hiding Progress menu for curriculum Ereader users. 
    if(isCurriculumEreaderUser) {
      modifiedMenuList.forEach((menuObj: any) => {
        if (menuObj.id === 'School' && menuObj.children instanceof Array) {
          menuObj.children.forEach((submenu: any, index: number) => {
            if(submenu.id === 'schoolProgress') {
              menuObj.children.splice(index, 1);
            }
          });
        }
      });
    }
    this.gNavigation(modifiedMenuList);
  }

  redirectUrl(data: any) {
    let url:any = (data === GlobalSettings.escapeUrl) ? GlobalSettings.escapeRedirectPath : data;
    const queryParamsIndex:any = url.indexOf('?');
    if (queryParamsIndex === -1) {
      this.router.navigate([url]);
      this.ngZone.run(() => this.router.navigate([url]));
    }
    const redirectedUrl =url.split('?')[0];
    const queryString = url.slice(queryParamsIndex + 1);
    const queryParamsArray = queryString.split('&');
    const queryParams: { [key: string]: string } = {};
    for (const param of queryParamsArray) {
      const [key, value] = param.split('=');
      queryParams[key] = value;
    }
    this.ngZone.run(() => this.router.navigate([redirectedUrl], { queryParams }));
  }
  requestPermission() {
  if (!("Notification" in window)) {
    return;
  }
  if (Notification.permission === "granted" && (browser.name === "safari" || browser.name === "firefox" || browser.name === "chrome" ||  browser.name === "edge") ) {
    this.safariNotification();
  } else if (Notification.permission === "granted") {
    this.browserNotification();
  } else if (Notification.permission !== "denied") {
    Notification.requestPermission().then((permission) => {
      if (permission === "granted" && (browser.name === "safari" || browser.name === "firefox" ||  browser.name === "chrome" || browser.name === "edge") ) {
          this.safariNotification();
        } else if (permission === "granted") {
          this.browserNotification();
        }
      });
    }
  }

  browserNotification(){
    const messaging = getMessaging();
    getToken(messaging, { vapidKey:this.data.fbc?.vapidKey })
    .then((currentToken: any) => {
      if (currentToken) {
          this.fcmCurrentToken = currentToken;
          this.data.FMCtoken = currentToken;
          const data = { token: currentToken };
          this.saveToken(data);
          this.subscriptions.push(
            this.saveToken(data).subscribe((res) => {this.listen()})
          );
        }
      })
    .catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
    });
  }
  listen() {
    if(isPlatformBrowser(this.platformId)){
      this.subscriptions.push( fromEvent<MessageEvent>(navigator.serviceWorker, 'message').subscribe((event) => {
        const message = event.data;
        this.redirectUrl(message.url)
      }));
    }
  }
  safariNotification(){
    this.subscriptions.push(this.getuserslatestnotifications().subscribe((res) => {
      if(res.length){
        res.forEach((notification : any) => {
           var myNotification = new Notification(notification.notificationTitle, {
            body: notification.notificationDescription,
            tag:  notification.screenPath,
            icon:'/favicon.ico'
          })
          myNotification.addEventListener('click',(event:any)=>{
            this.redirectUrl(event.target.tag)
          }); 
        })

      }
    }));
  }
  browserNotificationRedirect(data:any){
    if(data.currentTarget.data.url){
      this.redirectUrl(data.currentTarget.data.url)
    }
  }
  PendingPushNotificationsNative(): Observable<any>{
    return new Observable(observer => {
      checkForPendingPushNotificationsCallback((response: any) => {
        response = JSON.parse(response);
        if (response.data) {
           observer.next(response.data);
        }else{
          observer.next({})
        }
      });
    });
  }
  deleteUserDeviceTokenBrowser(): Observable<any> {
    if(this.data.FMCtoken){
      const url = this.baseUrl + ApiConstants.deleteuserdevicetoken ;
      let reqBody:any={"userDeviceToken":this.data.FMCtoken}
      return this.http.post<any>(url, reqBody)
    }else{
      return of([])
    }
 } 
 parsefbcString(data:string){
  let decodeFBC:any = Base64.decode(data);
  const jsonObject = JSON.parse(decodeFBC);
  const convertedObject = {
  apiKey: jsonObject.ApiKey,
  authDomain: jsonObject.AuthDomain,
  projectId: jsonObject.ProjectId,
  storageBucket: jsonObject.StorageBucket,
  messagingSenderId: jsonObject.MessagingSenderId,
  appId: jsonObject.AppId,
  vapidKey: jsonObject?.VapidKey
  };
  return convertedObject;
 }
 menuShowHide() {
    if(this.data.menuToggleEnvObject){
      this.data.globalNavigationMenuListStatic = this.data.globalNavigationMenuListStatic.filter((item: { id: string; children: any[]; }) => {
      if (!this.data.menuToggleEnvObject[item.id.toLocaleLowerCase()]) {
        return false;
      }
      if (item.children && item.id !== 'theme') {
         item.children = item.children.filter(child => this.data.menuToggleEnvObject[child.id.toLocaleLowerCase()]);
      }
      return true;
    });
      this.gNavigation(this.data.globalNavigationMenuListStatic);
    }else{
       this.gNavigation(this.data.globalNavigationMenuListStatic);
    }
  }
  updateGlobalNav(url: string) {
    let menuList = JSON.parse(JSON.stringify(this.data.globalNavigationMenuListStatic));
    menuList.forEach((menuObj: any) => {
      if (menuObj.id === 'bookshelf') {
        menuObj.url = url;
      }
    });
    this.gNavigation(menuList);
  }

  public convertTextToHTML(text:string){
    return  new DOMParser().parseFromString(text, 'application/xml');;
  }

  getCountryCodeForPendo() {
    return this.http.get(environment.countryCodeUrl)
      .pipe(
        tap(response => {
          return response;
        }),
        catchError(error => {
          return of([]);
        })
      );
  }  

  getThresholdValues() {
    let timestamp = new Date().getTime();
    const params = new HttpParams().set('env', environment.name + timestamp);
    const url = environment.assetsCdnUrl + 'thresholdValues.json';
    return this.http.request<any>('GET', url, { responseType: 'json', params })
      .pipe(
        tap(response => {
          return response;
        }),
        catchError(error => {
          return of([]);
        })
      );
  }

  
  downloadThresoldValues(jsonData) {
    const manifestVersionFileObj = {
      jsonFileType: this.constants.THRESHOLDVAL,
      contentUnitId: this.constants.THRESHOLDVAL,
      jsonData: jsonData,
      manifestVersion:'manifestVersion'
    };
    if (this.data.isInNative) {
      saveManifestCallback(manifestVersionFileObj, ((result: any) => {
        this.logger.logInfo('result');
      }));
      return new Observable();
    } else {
     const url: string = GlobalSettings.LocalNodeServerBaseUrl + '/api/download/saveManifestLocally/';
      return this.http.post<any>(url, { jsonInformation: manifestVersionFileObj, env: environment.name })
    
        .pipe(
          tap(saveresponse => {
            return saveresponse;
          }),
          catchError(error => {
            return of([]);
          })
        );
    }
  }

  readThresholdValues(manifestType) {
    let url
    if (!GlobalSettings.isBrowser) {
      if (this.data.isInNative) {       
          url = this.data.deviceRootPath + "/" + "downloaded_files" + "/" + manifestType + ".json";
       
      } else {
        url = GlobalSettings.LocalNodeServerStaticBaseUrl + `/serveFile/downloaded_files/${manifestType}/${manifestType}.json`;
      }
      return this.http.get(url)
        .pipe(
          tap(manifestResponse => {
            return manifestResponse;
          }),
          catchError(error => {
            return of([]);
          })
        );
    }
    else {
      let timestamp = new Date().getTime();
      const params = new HttpParams().set('env', environment.name + timestamp);
      url = environment.assetsCdnUrl + 'thresholdValues.json';      
      return this.http.request<any>('GET', url, { responseType: 'json', params })
        .pipe(
          tap(response => {
            return response;
          }),
          catchError(error => {
            return of([]);
          })
        );
    }
  }

  public checkForLessonExtrasExistence(spotOnData?: any , schemaVersionConst?: number): boolean {
    if (spotOnData && spotOnData.componentPageZoneMap) {
      const schemaVersion = spotOnData.componentPageZoneMap.schemaVersion;
      const pages = spotOnData.componentPageZoneMap.pages;
      if (schemaVersion && schemaVersion >= schemaVersionConst) {
        return Object.values(pages).some((page:any) => page.lessonExtrasData && page.lessonExtrasData.length > 0);
      }
    }
    return false;
  }

  setFeatureVisibility(): any {
    const { isBrowser, isDesktop, isNative } = GlobalSettings;
    const deviceType = this.getDeviceType();
    const isTablet = deviceType === getdeviceType.tablet;
    const isMobile = deviceType === getdeviceType.mobile;
         //browser desktop condition 
         if(isBrowser || isDesktop){
          this.data.isFeatureVisible=true
         }
        //mobile condiiton 
         if(isBrowser && isMobile){
          this.data.isFeatureVisible=false
         }
         if(isNative && isMobile){
          this.data.isFeatureVisible=false
         }
         //table condition 
         if(isBrowser && isTablet){
          this.data.isFeatureVisible=true
          }
            
         if(isNative && isTablet){
          this.data.isFeatureVisible=true
          }
  }
  isMobileTabletBrowser(): boolean {
    const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;
    const isMobileTablet = /iPhone|iPod|Android.*Mobile|Windows Phone|BlackBerry|iPad|Android/i.test(userAgent);
    const isIpadOS = navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && /MacIntel/.test(navigator.platform);
    return isMobileTablet || isIpadOS;
  }

  public toggleFullscreen(renderer: any) {
    const element = document.getElementById('page--home-container');
    if (!element) return;
    const isFullscreen = element.classList.contains('page--view-full-screen');
    this.data?.fullscreenResizedView?.next(!isFullscreen);
    this.triggerAnimation(renderer, element, 'page--view-updating');
    renderer[isFullscreen ? 'removeClass' : 'addClass'](element, 'page--view-full-screen');
    if (!isFullscreen) {
      this.data.showGotoForMobile = false;
    }
    this.gtmService.advanceTracking(BookInteractions,GTMEvents.FullScreen,{course_name: this.data.courseName, course_level: this.data.courseLevel, book_name: this.data?.selectedBook?.bookName, full_screen_mode: !isFullscreen ? "Yes" : "No", page_number: this.data.goToPageNmber, ...this.getChapterUnitLessonName()});
    this.setFocusOnMobile(isFullscreen);
  }
  
  public setFocusOnMobile(isFullscreen: boolean) {
    const windowWidth = window.innerWidth;
    const isMobileScreen = windowWidth < smallScreenMobileSize.smallScreenMobileSize;
    if (!isMobileScreen) return; 
    const focusTask = () => {
      if (isFullscreen) {
        const element:any = document.getElementById("Fullscreen");
        if (element) {
          this.addVisibleClass();
          element.children[0]?.focus();
        }
      } else {
        document.getElementById('fullScreenButton')?.focus();
      }
    };
    of(null).pipe(delay(500),first()).subscribe(focusTask);
  }

  public triggerAnimation(renderer: any,element: HTMLElement,animationClass: string): void {
    renderer.addClass(element, animationClass);
    of(null)
      .pipe(delay(1000), first())
      .subscribe(() => {
        renderer.removeClass(element, animationClass);
      });
  }

public addVisibleClass() {
  const windowWidth = window.innerWidth;
  const isMobileScreen = windowWidth < smallScreenMobileSize.smallScreenMobileSize;
  if(isMobileScreen){
    const navigationList:any = document.querySelector('.c-navigation-list');
    if (navigationList) {
      if (this.currentSubscription) {
        this.currentSubscription.unsubscribe();
      }
      navigationList.classList.remove('c-navigation-list--modified');
      this.currentSubscription = of(null).pipe(
        tap(() => {
          navigationList.classList.add('c-navigation-list--modified');
        }),
        delay(3000),
        tap(() => {
          this.resetNavAndTooltip(navigationList);
        }),first()
      ).subscribe();
    }
  }
}

//method to remove tooltip on each tab or click
public resetNavAndTooltip(navigationList: HTMLElement): void {
  navigationList.classList.remove('c-navigation-list--modified');
  const activeElement: HTMLElement | any = navigationList.querySelector(".active");
  const activeElementNextSiblingChildren = activeElement?.nextSibling?.children;
  const isTooltipPresent = activeElementNextSiblingChildren?.[0]?.hasAttribute('data-show');
  if (isTooltipPresent) {
    activeElementNextSiblingChildren?.[0]?.removeAttribute('data-show');
  }
}

// method to update a specific navigation state value
public updateNavigationState(key: string, value: any): void {
  this.data.fullScreenHotspotNavigationState.set(key, value);
}

// method to get a specific navigation state value
public getNavigationState(key: string): any {
  return this.data.fullScreenHotspotNavigationState.get(key);
}

//method to update hotspot zoom navigation event
public handleFrameNavigation = (action: 'next' | 'prev', event: Event) => {
  this.data.frameNavigation.next({ action, frameEvent: event?.type || 'click' });
};

public getChapterUnitLessonName() {
    const { chapters, units, lessons } = this.data?.TocData ?? {};
    let currentLesson: any;
    let currentUnit: any;
    let currentChapter: any;
    if (Object.keys(units).length > 0 && Object.keys(lessons).length > 0) {
      currentLesson = this.findCurrentLessonOrChapterByPageNo(lessons, this.data.goToPageNmber);
      if (currentLesson?.parentID) {
        currentUnit = units[currentLesson.parentID];
      }
    }
    if (Object.keys(chapters).length > 0) {
      currentChapter = this.findCurrentLessonOrChapterByPageNo(chapters, this.data.goToPageNmber);
    }

    return {
      unit_name: currentUnit?.title,
      chapter_name: currentChapter?.title,
      lesson_name: currentLesson?.title
    };
}

public findCurrentLessonOrChapterByPageNo(data: any, currentPageNumber: any) {
    for (const key in data) {
      if ((this.isCurrentPageIncluded(data[key].pageNumbers, currentPageNumber) || data[key].pageNumbers.includes(this.data?.actualPageNumber)) && data[key].componentId === this.data.selectedComponentId) {
        return data[key];
      }
    }
    return null;
}

public isCurrentPageIncluded(pageNumbers: Array<string>, currentPage: string) {
    return pageNumbers.some(page => {
      if (page === currentPage) {
        return true;
      } else if (page.includes('-')) {
        const pageArr = page.split('-');
        return pageArr.includes(currentPage);
      } else {
        return false;
      }
    });
}

getUrl(isMicroService: boolean, urlMicroServiceApi: string, urlCoreApi: string) {
  return isMicroService ? urlMicroServiceApi : urlCoreApi;
}

}
