import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot, ResolveFn } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, first, tap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { GlobalSettings } from 'src/app/global.settings';
import { ACEToken } from 'src/app/core/models/token';
import { ToastService } from 'src/app/services/toast.service';
import { Data } from '../models/data';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { CommonService } from './common.service';
import { concatMap } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { initializeApp } from "firebase/app";
import { CourseService } from './course.service';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class TokenResolver {
  isOCID: string = 'false';
  isSkipUserCheck: string = 'false';
  resp: any = new ACEToken;
  redisdata: any;
  isFirebaseCalled : boolean = false;
  currentUrl: string = '';
  currentContentID: string = '';
  constructor( @Inject(PLATFORM_ID) public platformId: Object,public courseService: CourseService,
  public commonService: CommonService,public activatedRoute: ActivatedRoute,private router: Router, private authService: AuthService, private cookieService: SsrCookieService, private data: Data, private toastService: ToastService) { }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    this.currentUrl = state.url;
    this.currentContentID = route.queryParams?.contentID;
    this.data.loadMenuconfigAfterLogin.next(true);
    this.activatedRoute.queryParams.subscribe((param) => {
      this.data.globalRoutUrl = param;
      if(param?.isExportBtn){
        this.data.isExportBtn = param?.isExportBtn === 'true' ? true : false;
        this.cookieService.set('isExportBtn', this.data.isExportBtn);
      }else if(this.data.isExportBtn == ''){
        this.data.isExportBtn = this.cookieService.get('isExportBtn') === 'false' ? false : true;
      }
      if (GlobalSettings.isBrowser && param.classStup && this.data.hideClassSetuploader) {
        this.data.clearCacheData = true;
        this.data.isLoadingLgoin.emit(true);
        this.data.hideClassSetuploader = false;
      }
      if(!state?.url?.includes('/school-work/progress')){
        let menuList = JSON.parse(JSON.stringify(this.data.globalNavigationMenuListStatic));
        menuList.forEach((menuObj: any) => {
        if (menuObj.id === 'School' && menuObj.children instanceof Array) {
           menuObj.children.forEach((submenu: any, index: number) => {
            if (submenu.id === 'schoolProgress') {
                submenu.url = '/school-work/progress';
            }
          });
        }
        });
        this.commonService.gNavigation(menuList);
      }
      if (param?.error === 'student-details-page') {
        this.commonService.globalSystemErrorPage$.next({ showErrorPage: true, statusCode: 403, errDetails: this.data.languageJson?.globalError?.errorCode['403']?.subTitle_1, from: "student-details-page" });
      } else {
        this.commonService.globalSystemErrorPage$.next({});
      }
    });
    this.toastService.close();
    if (GlobalSettings.isBrowser) {
      if (this.data.aceToken) {
        this.resp = this.data.aceToken;
        if (
          isPlatformBrowser(this.platformId) &&
          this.data.aceToken?.fbc &&
          GlobalSettings.isBrowser && !this.isFirebaseCalled
        ) {
          this.data.fbc = this.commonService.parsefbcString(this.data.aceToken.fbc);
          initializeApp(this.data.fbc);
          this.commonService.requestPermission();
          this.isFirebaseCalled=true;
        }
        const meeUserId = this.data.aceToken.MEEUserId.split('').reverse().join('');
        const isMeeUser = ['mee', 'macx']?.includes((this.data.aceToken?.AccountKey ?? '').toLowerCase());
        if (!this.cookieService.get(meeUserId) && isMeeUser) {
          const expirationDate = new Date();
          expirationDate.setDate(expirationDate.getDate() + 14);
          this.cookieService.set(meeUserId, 'Show',expirationDate);
        }
        return this.resp;
      } else if (this.data.aceToken === undefined && this.cookieService.get('isSession') != "true" && this.cookieService.get('isKMLI_ssr') != "true") {
        this.authService.redirectUrl(state.url);
        this.router.navigate(['/']);
        return this.resp
      } else {
        GlobalSettings.isBrowser ? this.data.isLoadingKmli.next(true) : undefined;
         return this.authService.getRedisData().pipe(
          concatMap((response:any) => {
            this.data.aceToken = JSON.parse(JSON.stringify(response));
            this.courseService.mac25Coursedisable(JSON.parse(JSON.stringify(response)), this.currentUrl, this.currentContentID)
            if(response && response['.issued'] && new Date(response['.issued']).getTime() < Number(environment.globalCookieExpiryDate)){
              this.cookieService.delete('isKMLI_ssr');
              this.cookieService.delete("isSession");
              this.cookieService.delete("token_key");
              this.cookieService.delete("langKey");
              this.data.aceToken=null;
            }
            if (
                isPlatformBrowser(this.platformId) &&
                this.data.aceToken?.fbc &&
                GlobalSettings.isBrowser && !this.isFirebaseCalled
            ) {
                this.data.fbc = this.commonService.parsefbcString(this.data.aceToken.fbc);
                initializeApp(this.data.fbc);
                this.commonService.requestPermission();
                this.isFirebaseCalled = true;
            }
            if (!this.data.aceToken && isPlatformBrowser(this.platformId)) {
                this.authService.redirectUrl(state.url);
                this.router.navigate(['/']).then(() => {
                  GlobalSettings.isBrowser ? this.data.isLoadingKmli.next(false) : undefined;
                });
            }
            let langKey = this.cookieService.get('langKey');
            if (this.cookieService.get('langKey_' + this.data.aceToken?.MEEUserId)) {
                langKey = this.cookieService.get('langKey_' + this.data.aceToken?.MEEUserId);
            }
            return this.commonService.getTheam().pipe(
                concatMap((themeResponse:any) => {
                  this.data.themeList = themeResponse?.themeDetails;
                  this.commonService.getUniqueThemeCategories();
                  this.commonService.getUserSelectedTheme().subscribe((userSelected: any) => {
                    this.data.userSelectedThemeId = userSelected.userTheme?.themeId ? userSelected.userTheme?.themeId : '';
                    this.data.userShowAnswerConfiguration = userSelected?.userDomainConfiguration?.showAnswers;
                    this.data.institutionLogo = userSelected?.userDomainConfiguration?.institutionalLogoUrl ? `${userSelected.userDomainConfiguration.institutionalLogoUrl}?v=${Math.random()}` : this.data.institutionLogo;
                    this.data.isInstitutionLogo = this.data.institutionLogo ? true : false;
                    this.commonService.getNameById();
                  });
                  
                    return this.commonService.loadJSON(langKey).pipe(
                        tap(response2 => {
                            this.data.languageJson = response2;
                            this.data.languageOptions = response2.languageOptions;
                            this.data.isDataThemeSet.next(true);
                            this.courseService.createAssesmentMenu([]);
                            this.data.isLoadingKmli.next(false)
                        })
                    );
                })
            );
        }),

          catchError(error => { return of('No data'); })
        );
      }
    } else {
      if (GlobalSettings.isDesktop || GlobalSettings.isNative) {
      if(this.data.aceToken){
        const meeUserId = this.data.aceToken?.MEEUserId.split('').reverse().join('');
        const isMeeUser = ['mee', 'macx'].includes((this.data.aceToken?.AccountKey ?? '').toLowerCase());
        if (!this.cookieService.get(meeUserId) && isMeeUser) {
          const expirationDate = new Date();
          expirationDate.setDate(expirationDate.getDate() + 14);
          this.cookieService.set(meeUserId, 'Show',expirationDate);
        } 
      } 
    }     
      return this.resp;
    }
  }
}
