// pendo.service.ts

import { Injectable } from '@angular/core';
import { Data } from '../core/models/data';
import { DeviceDetectorService } from 'ngx-device-detector';
import { GlobalSettings } from '../global.settings';
// @ts-ignore
import { pendoScript } from "./pendoUtility.js";
import { environment } from 'src/environments/environment';
import { CommonService } from '../core/services/common.service';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { Observable, first } from 'rxjs';

declare let  pendo: any;
let authToken;
@Injectable({
  providedIn: 'root',
})
export class PendoService {
  pendoAccountId?: string;
  pendoDeviceName?: string;
  pendoMEEUserId: any;
  pendoRole: any;
  pendoOsVersion?: string;
  pendoBrowser?: string;
  pendoBrowserVersion?: number;
  pendoCourseName?: string;
  pendoCountryCode?: string;
  authToken: any;
  apiKey: any;
  visitor: any= {};
  isPendoInit: boolean = false;
  public isPlatformBrowser = true;
  CCAPIFail: number = 0;
  maxRetryAttempts: number = parseInt(`${environment.retryAttempts}`) || 0;

  constructor(private data: Data, private deviceService: DeviceDetectorService, private commonService: CommonService, public cookieService: SsrCookieService,) { }

  async loadPendo() {
    this.apiKey = `${environment.pendoAPIKey}`;
    await pendoScript(this.apiKey); // Ensure pendoScript is loaded before proceeding
    this.authToken = this.data.aceToken;
    if (this.data.selectedCourse) {
      this.pendoCourseName = this.data.selectedCourse?.courseName + " " + this.data.selectedCourse?.courseLevel;
    } else {
      this.pendoCourseName = "";
    }
    this.pendoMEEUserId = this.authToken?.MEEUserId;

    this.pendoBrowser = this.deviceService.getDeviceInfo().browser;
    this.pendoBrowserVersion = parseFloat(this.deviceService.getDeviceInfo().browser_version);
    let userAgent = navigator.userAgent;
    if (/Linux/.test(userAgent)) {
      let match = /Linux (.+?)(?=[;)])/i.exec(userAgent);
      this.pendoOsVersion = match ? "Linux " + match[1] : '';
    } else {
      this.pendoOsVersion = this.deviceService.getDeviceInfo().os_version;
    }
    this.pendoAccountId = this.data.aceToken?.ProductFrameworkTypeIdentifier == 'LMS_Curriculum' ? 'Altura' : 'MEE';
    this.setPendoDevice();
    this.setPendoRole();
    this.pendoInit();
    if(!this.pendoCountryCode && this.CCAPIFail <= this.maxRetryAttempts) {
      this.getCountryCode.pipe(first()).subscribe((countryCode: any) => {
        if (countryCode) {
          this.data.countryCode = countryCode;
          this.pendoUpdate({
            countryCode
          });
        }
      });
    }
  }

  setPendoDevice() {
    if (this.deviceService.isMobile()) {
      this.pendoDeviceName = 'Mobile';
    } else if (this.deviceService.isTablet()) {
      this.pendoDeviceName = 'Tablet';
    } else if (this.deviceService.isDesktop()) {
      this.pendoDeviceName = 'Desktop';
    }
  }

  setPendoRole() {
    this.pendoRole = this.authToken?.Role && this.authToken.Role.toLowerCase() === 'instructor' ? 'teacher' : this.authToken?.Role?.toLowerCase();
  }

  pendoInit() {
    let visitor: any = this.getVisitorObj();
    if (GlobalSettings.isBrowser) {
      visitor.browser = this.pendoBrowser;
      visitor.browserVersion = this.pendoBrowserVersion
      pendo.initialize({
        visitor,
        account: {
          id: this.pendoAccountId
        }
      });
    } else {
      visitor.deviceName = this.pendoDeviceName;
      pendo.initialize({
        visitor,
        account: {
          id: this.pendoAccountId
        }
      });
    }
    this.isPendoInit = true;
  }

  pendoUpdate(payloadToUpdate:any) {
    this.visitor = {
      ...this.visitor,
      ...payloadToUpdate
    }   
      pendo?.updateOptions({
      "visitor": {
        ...this.visitor
      },
      account: {
        id: this.pendoAccountId
      }
    });
  }

  getCountryCode = new Observable((subscriber) => {
    this.commonService.getCountryCodeForPendo().subscribe((countryResponse: any) => {
      if (countryResponse && 'country' in countryResponse) {
        const countryCode = (countryResponse as { countryCode: string }).countryCode;
        this.data.pendoCountryCode = countryCode;
        this.pendoCountryCode = countryCode;
        this.cookieService.set('pendoCountryCode', countryCode);
        subscriber.next(countryCode);
      } else {
        this.CCAPIFail += 1;
      }
    });
  });


  getVisitorObj() {
    this.visitor = {
      id: this.pendoMEEUserId,
      role: this.pendoRole,
      device: this.pendoOsVersion,
      InstitutionCode: this.data.aceToken?.AccountKey,
      language_switched: this.data.languageSwitched
    };
    if(this.pendoCountryCode) {
      this.visitor['countryCode'] = this.pendoCountryCode;
    }
    if(this.pendoCourseName) {
      this.visitor['course'] = this.pendoCourseName;
    }
    return this.visitor;
  }

  trackEvent(eventName: string, eventData?: any) {
    if (pendo && pendo.track) {
      pendo.track(eventName, eventData);
    } 
  }
  validateEnvironment(additionalData: any = {}) {
    this.pendoUpdate(additionalData);   
  }

}
