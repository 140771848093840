import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule} from '@angular/platform-browser';
import { AuthService } from '../app/core/services/auth.service';
import { FooterComponent } from '../app/shared/footer/footer.component';
import { HeaderComponent } from '../app/shared/header/header.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IConstants } from './core/models/constants';
import { AuthResolver } from './core/services/auth.resolver';
import { interceptor } from './core/services/interceptor';
import { ErrorPageComponent } from './error-page/error-page.component';
import { LanguageExtComponent } from './language-ext/language-ext.component';
import { LoginComponent } from './login/login.component';
import { LanguageExtService } from './services/language-ext.service';
import { GtmDataTrackEventObject, GtmPageViewObject } from './core/models/gtm-models/gtm';
import { ErrorCatchingInterceptor } from './core/services/error-catching.interceptor';
import { SharedModule } from './shared/shared.module';
import { ContentUpdateComponent } from 'src/app/shared/components/content-update/content-update.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { LoaderComponent } from 'src/app/shared/loader/loader.component'
import { LoaderInterceptor } from './core/services/loader.interceptor'
import { ToastMessageComponent } from './shared/components/toast-message/toast-message.component';
import { ZoomControlComponent } from './features/bookreader/zoom-control/zoom-control.component';
import { GlobalNavigationComponent } from './shared/global-navigation/global-navigation.component';
import { DialogComponent } from './shared/components/dialog/dialog.component';
import { SystemErrorModule } from './system-error/system-error.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DataProcessingPopupComponent } from './shared/data-processing-popup/data-processing-popup.component';
import { EmbeddedLinkViewerComponent } from './shared/embedded-link-viewer/embedded-link-viewer.component';
import { MicroServiceService } from './services/micro-service.service';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ErrorPageComponent,
    LanguageExtComponent,
    HeaderComponent,
    FooterComponent,
    ContentUpdateComponent,
    GlobalNavigationComponent,
    ToastMessageComponent,
    ZoomControlComponent,
    LoaderComponent,
    DialogComponent,
    DataProcessingPopupComponent,
    EmbeddedLinkViewerComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SharedModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    SystemErrorModule
    
    
  ],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (appInitService: MicroServiceService) =>{
        return () => appInitService.initializeApp();
      },
      deps: [MicroServiceService],
      multi: true
    },
    // provideClientHydration(), // Enable client-side hydration
    AuthService,
    CookieService,
    LanguageExtService,
    IConstants,
    AuthResolver,
    GtmDataTrackEventObject,
    GtmPageViewObject,
    { provide: HTTP_INTERCEPTORS, useClass: interceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorCatchingInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }