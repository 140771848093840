import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { Observable, of, Subscriber } from 'rxjs';
import { catchError, first, switchMap, tap } from 'rxjs/operators';
import { ICourse } from 'src/app/core/models/course';
import { Data } from 'src/app/core/models/data';
import { ManifestVersionInfo } from 'src/app/core/models/manifestVersions';
import { GlobalSettings } from 'src/app/global.settings';
import { environment } from 'src/environments/environment';
import { ApiConstants } from '../constant/api.constant';
import { FileDownloadedStatus } from '../models/download-manager-models/download-manager.model';
import { LoggerService } from './logger.service';
import { PDResourceTypes, hwResourceTypes } from '../models/enum';
@Injectable({
  providedIn: 'root'
})
export class CourseService {
  baseUrl: string = ApiConstants.aceApiBaseUrl;
  currentPath: string = "";
  env: string = environment.name;
  isCallMenuApi: boolean = false;
  constructor(
    private http: HttpClient,
    private data: Data,
    private loggerService: LoggerService,
    public cookieService: SsrCookieService,
  ) {
  }

  getCourses(meeUserId?: any, cookie_name?: any, cookieKey?: any, clearCacheData?:any): Observable<ICourse[]> {
    if (GlobalSettings.isBrowser) {
      let url: string = '';
      if(this.data.clearCacheData){
        url = this.baseUrl + ApiConstants.getSubscriptionDetailUrl + '?clearClassCacheData='+this.data.clearCacheData;
        this.data.clearCacheData = false;
      } 
      else if (cookie_name != '') {
        url = this.baseUrl + ApiConstants.getSubscriptionDetailUrl + '?isClearCache=true';
      } else {
        url = this.baseUrl + ApiConstants.getSubscriptionDetailUrl;
      }
      return this.http.get<any>(url)
        .pipe(
          tap(COURSES => {
            this.data.subscriptionDetails = COURSES;
            this.createAssesmentMenu(COURSES);
          }),
          catchError(this.handleError('getCourses', []))
        );
    }
    else if (GlobalSettings.isNative) {
      return new Observable(observer => {
        let data = {
          "meeUserId": meeUserId,
          "cookieName": cookie_name,
          "cookieKey": cookieKey,
          "clearClassCacheData":this.data.clearCacheData
        }
        this.data.clearCacheData = false;
        getSubscriptionCallback(data, (result: any) => {
          if (typeof result === 'string') {
            result = JSON.parse(result);
          }
          observer.next(result);
        });
      });
    }
    else {
      let url: string = GlobalSettings.LocalNodeServerBaseUrl + '/api/class/getsubscriptiondetails'
      if(this.data.clearCacheData){
        url = GlobalSettings.LocalNodeServerBaseUrl + '/api/class/getsubscriptiondetails?clearClassCacheData='+this.data.clearCacheData;
        this.data.clearCacheData = false;
      } 
      else if (cookie_name != '') {
        url = GlobalSettings.LocalNodeServerBaseUrl + '/api/class/getsubscriptiondetails?isClearCache=true';
      }
      let params = new HttpParams().set('env', environment.name).set('meeUserID', meeUserId).set('isForObv', this.data.isOfflineUser);
      return this.http.request<ICourse[]>('GET', url, { responseType: 'json', params })
        .pipe(
          tap(COURSES => {
            this.data.subscriptionDetails = COURSES;
          }),
          catchError(this.handleError('getCourses', []))
        );
    }
  }

  public getSpotOnDataFromServer(contentUnitId: string, manifestVersion: any, isForObv = false): Observable<any> {
    let manifestVersionLocal = manifestVersion;
    manifestVersionLocal = '';
    let manifestDataVersion;
    let manifestData: any = this.data.manifestVerisonData?.filter((manifest: any) => manifest.manifestIdentifier === contentUnitId && manifest.manifestType === "spotOn");
    if (manifestData && manifestData.length > 0) {
      manifestDataVersion = manifestData[0];
    }
    if (GlobalSettings.isNative) {
      return this.nativeAPICall(contentUnitId, manifestVersion, manifestDataVersion, manifestVersionLocal);
    } else if (GlobalSettings.isBrowser) {
      const url = this.baseUrl + ApiConstants.getHotSpotManifestUrl + `?contentUnitId=${contentUnitId}&manifestVersion=${manifestVersionLocal}`;
      return this.http.get<any>(url).pipe(
        tap(() => {
          this.loggerService.logInfo('fetch spotOn Manifest for contentUnitId-' + contentUnitId + ' and ManifestVersion-' + manifestVersionLocal);
        }),
        catchError(this.handleError('getSpotOn', []))
      );
    } else {
      const url: string = GlobalSettings.LocalNodeServerBaseUrl + '/api/class/gethotspotmanifest';
      let manifestDataVersion: any;
      if (manifestData && manifestData.length > 0) {
        manifestDataVersion = manifestData[0];
      }
      let params = new HttpParams()
        .set('env', environment.name)
        .set('contentUnitId', contentUnitId)
        .set('manifestVersion', manifestDataVersion?.version ? manifestDataVersion.version : manifestVersionLocal)
        .set('isForObv',isForObv);
      return this.http.request<any[]>('GET', url, { responseType: 'json', params })
        .pipe(
          tap(response => {
            return response;
          }),
          catchError(this.handleError('getSpotOnData', []))
        );
    }
  }

  nativeAPICall(contentUnitId: string, manifestVersion: any, manifestDataVersion: any, manifestVersionLocal: any) {
    const params = { contentUnitId: contentUnitId, manifestVersion: manifestDataVersion?.version ? manifestDataVersion.version : manifestVersionLocal };
    return new Observable(observer => {
      getHotspotManifestCallBack(params, (result: any) => {
        result = JSON.parse(JSON.stringify(result));
        observer.next(result);
      });
    });
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      this.loggerService.logInfo(error);
      return of(result as T);
    };
  }

  appendMeeUserId(reqBody: any, apiUrl: string) {
    let url: string = "";
    if (!reqBody?.key?.includes(this.data?.aceToken?.MEEUserId)) {
      url = this.baseUrl + apiUrl + `?key=${reqBody.key}_${this.data?.aceToken?.MEEUserId}`;
      reqBody.key = `${reqBody.key}_${this.data?.aceToken?.MEEUserId}`;
    } else {
      url = this.baseUrl + apiUrl + `?key=${reqBody.key}`;
    }
    return {
      reqBody: reqBody,
      url: url
    };
  }

  commonSetRedis(reqBody: any): Observable<any> {
    let url: string = this.baseUrl + ApiConstants.setRedis;
    if (!reqBody?.key?.includes(this.data?.aceToken?.MEEUserId)) {
      reqBody.key = `${reqBody.key}_${this.data?.aceToken?.MEEUserId}`;
    }
    return this.http.post(url, reqBody)
      .pipe( 
        tap(response => response),
        catchError(this.handleError('commonSetRedis', []))
      );
  }

  commonGetRedis(reqBody: any): Observable<any> {
    let reqBodyObj: any = this.appendMeeUserId(reqBody, ApiConstants.getRedis);
    return this.http.get(reqBodyObj?.url, reqBodyObj?.reqBody)
      .pipe(
        tap(response => response),
        catchError(this.handleError('commonSetRedis', []))
      );
  }

  commonClearRedis(reqBody: any): Observable<any> {
    let reqBodyObj : any= this.appendMeeUserId(reqBody, ApiConstants.deleteRedis);
    return this.http.get(reqBodyObj?.url, reqBodyObj?.reqBody)
      .pipe(
        tap(response => {
        }),
        catchError(this.handleError('commonSetRedis', []))
      );
  }

  getManifestVersions(manifestVersionInfo: ManifestVersionInfo[],isForObv = false): Observable<any> {
    if (GlobalSettings.isNative) {
      return new Observable(observer => {
        getManifestVersionCallback(manifestVersionInfo, (result: any) => {
          result = JSON.parse(result);
          observer.next(result);
        });
      });
    } else if (GlobalSettings.isDesktop) {
      const url: string = GlobalSettings.LocalNodeServerBaseUrl + '/api/class/getmanifestversion';
      return this.http.post<any>(url, { 'manifestVersions': manifestVersionInfo, 'env': environment.name, 'isForObv': isForObv })
        .pipe(
          tap(versionData => {
            this.loggerService.logInfo(versionData);
          }),
          catchError(this.handleError('gettoc', []))
        );
    } else {
      return new Observable();
    }
  }
  // to save manifestversion data in local folder
  saveManifestLocally(jsonInformation: any): Observable<any> {
    if (GlobalSettings.isNative) {
      return new Observable(observer => {
        saveManifestCallback(jsonInformation, ((res: any) => {
          this.loggerService.logInfo(res);
          if (GlobalSettings.isNative) {
            let resData = JSON.parse(res);
            observer.next(resData.result);
          } else {
            observer.next(JSON.parse(res.result));
          }
        }));
      });
    } else {
      const url: string = GlobalSettings.LocalNodeServerBaseUrl + '/api/download/saveManifestLocally/';
      return this.http.post<any>(url, { jsonInformation: jsonInformation, env: environment.name })
        .pipe(
          tap(response => {
            this.loggerService.logInfo('fetched redirectionUrl');
            return response;
          }),
          catchError(this.handleError('saveManifestLocally', []))
        );
    }
  }

  saveBookMarkData(bookmarkData: any, meeUserID: any, accountID: any): Observable<any> {
    if (GlobalSettings.isBrowser) {
      // let bookmarkNotesData = getSaveOrUpdateBookmarkNotesParam(bookmarkData);
      let bookmarkNotesData = { BookmarkNotesData: bookmarkData };
      const url: string = this.baseUrl + ApiConstants.saveUpdateBookmarkNotesUrl;
      return this.http.post(url, bookmarkNotesData)
        .pipe(
          tap(response => {
            this.data.bookmarknotesdata = [];
            return response;
          }),
          catchError(this.handleError('SaveOrUpdateBookmarkNotes', []))
        );
    }
    else if (GlobalSettings.isNative) {
      bookmarkData = { BookmarkNotesData: bookmarkData, AccountID: accountID };
      return new Observable(observer => {
        saveOrUpdateBookmarkNotesCallback(bookmarkData, ((res: any) => {
          let response = JSON.parse(res);
          observer.next(response.result);
        }));
      });
    } else {
      const url: string = GlobalSettings.LocalNodeServerBaseUrl + '/api/bookmarkAndNotes/saveOrUpdateBookmarkNotes';
      return this.http.post<any>(url, {
        BookmarkNotesData: bookmarkData, env: environment.name, AccountID: accountID,
        meeUserId: meeUserID, isForObv: this.data.isOfflineUser
      })
        .pipe(
          tap(response => {
            return response;
          }),
          catchError(this.handleError('SaveOrUpdateBookmarkNotes', []))
        );
    }
  }

  getBookmarkNotesData(reqBody: any): Observable<any> {
    if (GlobalSettings.isBrowser) {
      const url = this.baseUrl + ApiConstants.getBookmarkNotesUrl + `?ContentUnitId=${reqBody.contentUnitId}`;
      return this.http.get<any>(url).pipe(
        tap(response => {
          return response;
        }),
        catchError(this.handleError('getBookmarkNotesData', []))
      );
    }
    else if (GlobalSettings.isNative) {
      return new Observable(observer => {
        getBookmarkNotesDataCallback(reqBody, ((res: any) => {
          let response = JSON.parse(res);
          observer.next(response.result);
        }));
      });

    } else {
      const url: string = GlobalSettings.LocalNodeServerBaseUrl + '/api/bookmarkAndNotes/getBookmarkNotesData';
      let params = new HttpParams().set('contentUnitID', reqBody.contentUnitID).set('accountID', reqBody.accountID).set('env', environment.name).set('meeUserId', reqBody.MeeUserId).set('isForObv', this.data.isOfflineUser);
      return this.http.request('GET', url, { responseType: 'json', params })
        .pipe(
          tap(response => {
            return response;
          }),
          catchError(this.handleError('getBookmarkNotesData', []))
        );
    }
  }

  syncBookmarkNotesData(reqBody: any): Observable<any> {
    if (GlobalSettings.isNative) {
      return new Observable(observer => {
        syncBookmarkNotesDataCallback(reqBody, ((res: any) => {
          observer.next(true);
        }));
      });
    } else {
      const url: string = GlobalSettings.LocalNodeServerBaseUrl + '/api/bookmarkAndNotes/syncBookmarkAndNotes';
      let params = new HttpParams().set('contentUnitID', reqBody.contentUnitID).set('accountID', reqBody.accountID).set('env', environment.name).set('meeUserId', reqBody.MeeUserId);
      return this.http.request('GET', url, { responseType: 'json', params })
        .pipe(
          tap(response => {
            return response;
          }),
          catchError(this.handleError('syncBookmarkAndNotes', []))
        );
    }
  }

  deleteBookmarkNotes(bookmarkNotesData: any): Observable<any> {
    if (GlobalSettings.isBrowser) {
      const url = this.baseUrl + ApiConstants.deleteBookmarkNotesUrl;
      return this.http.post(url, bookmarkNotesData)
        .pipe(
          tap(response => {
            return response;
          }),
          catchError(this.handleError('DeleteBookmarkNotesData', []))
        );
    }
    else if (GlobalSettings.isNative) {
      return new Observable(observer => {
        deleteBookmarkNotesDataCallback(bookmarkNotesData, ((result: any) => {
          observer.next(result);
        }));
      });
    } else {
      const url: string = GlobalSettings.LocalNodeServerBaseUrl + '/api/bookmarkAndNotes/DeleteBookmarkNotesData';
      const params = Object.assign(bookmarkNotesData, { env: environment.name },{isForObv: this.data.isOfflineUser});
      return this.http.post<any>(url, params)
        .pipe(
          tap(response => {
            return response;
          }),
          catchError(this.handleError('DeleteBookmarkNotesData', []))
        );
    }
  }

  checkFileExists(filePath: string): Observable<any> {
    if (GlobalSettings.isNative) {
      return new Observable(observer => {
        checkFileExistsCallback(filePath, ((status: any) => {
          status = JSON.parse(status).result;
          observer.next(status);
        }));
      });
    } else {
      const url: string = GlobalSettings.LocalNodeServerBaseUrl + '/api/download/checkFileExists';
      return this.http.post<any>(url, { filePath: filePath, env: environment.name })
        .pipe(
          tap(fileStatus => {
            return fileStatus;
          }),
          catchError(this.handleError('checkFileExists', []))
        );
    }
  }
  checkFileExistsGlobal(filePath: string,isForObv=false): Observable<any> {
    if (GlobalSettings.isNative) {
      return new Observable(observer => {
        checkFileExistsGlobalCallback(filePath, ((status: any) => {
          status = JSON.parse(status).result;
          observer.next(status);
        }));
      });
    } else {
      const url: string = GlobalSettings.LocalNodeServerBaseUrl + '/api/download/checkFileExistsGlobal';
      return this.http.post<any>(url, {isForObv:isForObv, filePath: filePath, env: environment.name })
        .pipe(
          tap(fileStatus => {
            return fileStatus;
          }),
          catchError(this.handleError('checkFileExists', []))
        );
    }
  }

  downloadData(reqBody: any, isForObv = false): Observable<any> {
    if (GlobalSettings.isNative) {
      return new Observable(observer => {
        downloadDataCallback(reqBody, ((downloadStatus: any) => {
          downloadStatus = JSON.parse(downloadStatus);
          if (downloadStatus.status === 'Success') {
            observer.next(true);
          } else {
            observer.next(false);
          }
        }));
      });
    } else {
      const url: string = GlobalSettings.LocalNodeServerBaseUrl + '/api/download/downloadContent';
      return this.http.post<any>(url, {isForObv:isForObv, unitData: reqBody, env: environment.name })
        .pipe(
          tap(fileStatus => {
            return fileStatus;
          })
        );
    }
  }

  downloadActivityData(reqBody: any,isForObv = false): Observable<any> {
    if (GlobalSettings.isNative) {
      return new Observable(observer => {
        downloadDataCallback(reqBody, ((downloadStatus: any) => {
          downloadStatus = JSON.parse(downloadStatus);
          observer.next(downloadStatus);
        }));
      });
    } else {
      const url: string = GlobalSettings.LocalNodeServerBaseUrl + '/api/download/downloadAssets';
      return this.http.post<any>(url, { downloadAssets: reqBody, env: environment.name,isForObv:isForObv })
        .pipe(
          tap(fileStatus => {
            return fileStatus;
          }),
          catchError(this.handleError('downloadActivityData', []))
        );
    }
  }

  downloadAssetByBatches(reqBody: any): Observable<any> {
    if (GlobalSettings.isNative) {
      return new Observable(observer => {
        downloadAssetBatchesCallback({batches: [reqBody]}, ((downloadStatus: any) => {
          observer.next(true);
        }));
      });
    } else {
      const url: string = GlobalSettings.LocalNodeServerBaseUrl + '/api/download/downloadAssetBatches';
      return this.http.post<any>(url, {batches: [reqBody]})
        .pipe(
          tap(status => {
            return status;
          }),
          catchError(this.handleError('downloadAssetByBatches', []))
        );
    }
  }

  cancelDownloadByBatchIds(reqBody: any): Observable<any> {
    if (GlobalSettings.isNative) {
      return new Observable(observer => {
        cancelAssetBatchesCallback({cancelBatches: reqBody},((cancelStatus: any)=>{
          observer.next(true);
        }));
      });
    } else {
      const url: string = GlobalSettings.LocalNodeServerBaseUrl + '/api/download/cancelAssetBatches';
      return this.http.post<any>(url, { cancelBatches: reqBody })
        .pipe(
          tap(status => {
            return status;
          }),
          catchError(this.handleError('cancelDownloadByBatchIds', []))
        );
    }
  }

  deleteData(deleteUrls: any, deleteAllFlag = false, referenceId: any) {
    if (GlobalSettings.isNative) {
      return new Observable(observer => {
        deleteFilesCallback(deleteUrls, deleteAllFlag, referenceId, ((deleteStatus: any) => {
          deleteStatus = JSON.parse(deleteStatus);
          if (deleteStatus.status === 'Success') {
            observer.next(true);
          } else {
            observer.next(false);
          }
        }));
      });
    } else {
      const apiUrl: string = GlobalSettings.LocalNodeServerBaseUrl + '/api/download/assets';
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
        body: { "sourceUrls": deleteUrls, "removeAllStatus": deleteAllFlag, referenceId: referenceId },
        env: environment.name
      };
      return this.http.delete<any>(apiUrl, httpOptions)
        .pipe(
          tap(fileStatus => {
            return fileStatus;
          }),
          catchError(this.handleError('Asset Remove', false))
        );
    }
  }

  public getSpotOnDataFromLocalFile(contentUnitId: string, manifestVersion: string): Observable<any> {
    if (GlobalSettings.isNative) {
      let url = this.data.deviceRootPath + "/" + "downloaded_files" + "/" + "spoton" + "/" + contentUnitId + ".json";

      return this.http.get(url)
        .pipe(
          tap(() => {
          }),
          catchError(this.handleError('Error in getManifestByContentUnitId', []))
        );

    } else {
      let localDataUrl: string = '';
      localDataUrl = GlobalSettings.LocalNodeServerStaticBaseUrl + `/serveFile/downloaded_files/spoton/${contentUnitId}.json`;
      return this.http.get<any[]>(localDataUrl)
        .pipe(
          tap(manifestResponse => {
            return manifestResponse;
          }),
          catchError(this.handleError('getRedirectUrl', false)));
    }
  }

  getManifestVersionFile(): Observable<any> {
    if (GlobalSettings.isNative) {
      const url: string = this.data.deviceRootPath +
        `/downloaded_files/manifestVersions/manifestVersions.json`;
      return this.http.get(url)
        .pipe(
          tap(MANIFESTVERSIONRESPONSE => {
            this.loggerService.logInfo(`fetched MANIFESTVERSIONRESPONSE`);
          }),
          catchError(this.handleError('getsubscriptiondetails', []))
        );

    } else {
      //If is browser than fetch manifest version data from localstorage
      const url: string = GlobalSettings.LocalNodeServerStaticBaseUrl +
        `/serveFile/downloaded_files/manifestVersions/manifestVersions.json`;
      return this.http.get(url)
        .pipe(
          tap(MANIFESTVERSIONRESPONSE => {
            this.loggerService.logInfo(`fetched MANIFESTVERSIONRESPONSE`);
          }),
          catchError(this.handleError('getsubscriptiondetails', []))
        );
    }
  }

  getResourceBankRedirectionUrl(contentUnitId: any): Observable<any> {
    if (GlobalSettings.isBrowser) {
      const url: string = this.baseUrl + `coreplatform/getresourcebankredirectionurl?contentUnitId=${contentUnitId}`;
      return this.http.post<any>(url, null)
        .pipe(
          tap(ResourceBankRedirectionUrl => {
            this.loggerService.logInfo(`fetch ResourceBankRedirectionUrl NODE_SERVER_OFF OR isBrowser for contentUnitId-` + contentUnitId + ' is successful' + ResourceBankRedirectionUrl);
          }),
          catchError(this.handleError('getResourceBankRedirectionUrl', []))
        );
    }
    else if (GlobalSettings.isNative) {
      let contentUnitID = {
        "ContentUnitId": contentUnitId
      };
      return new Observable(observer => {
        getResourceBankRedirectionUrlCallback(contentUnitID, ((status: any) => {
          status = JSON.parse(status).result;
          observer.next(status);
        }));
      });
    } else {
      const url: string = GlobalSettings.LocalNodeServerBaseUrl + '/api/class/getresourcebankredirectionurl';
      return this.http.post<any>(url, { contentUnitId: contentUnitId, env: environment.name })
        .pipe(
          tap(ResourceBankRedirectionUrl => {
            this.loggerService.logInfo(`fetch ResourceBankRedirectionUrl for contentUnitId-` + contentUnitId + ' is successful' + ResourceBankRedirectionUrl);
          }),
          catchError(this.handleError('getResourceBankRedirectionUrl', []))
        );
    }
  }

  getChallengeActivityInfo(reqBody: any): Observable<any> {
    if (GlobalSettings.isBrowser) {
      let url: string = this.baseUrl + ApiConstants.challengeActivityInfo;
      return this.http.post(url, reqBody)
        .pipe(
          tap(response => {
            return response;
          }),
          catchError(this.handleError('challengeactivityinfo', []))
        );
    } else if (GlobalSettings.isNative) {
      return new Observable(observer => {
        getChallengeActivityInfoCallback(reqBody, (res: any) => {
          res = JSON.parse(res.result);
          observer.next(res);
        });
      });
    } else {
      const url: string = GlobalSettings.LocalNodeServerBaseUrl + '/api/progress/getChallengeActivityInfo';
      return this.http.post(url, { contentUnitIds: reqBody });
    }
  }

  orientationHandlerforalldevice(backevent: any = ``) {
    if (GlobalSettings.isNative) {
      let data = {
        "device": this.data.deviceType,
        "isBooklaunched": backevent ? false : true,
      };
      orientationHandlerCallback(data);
    }
  }

  downloadImages(imageUrls: any, imageType: any, rootPath: any, isForObv = false) {
    let reqBody = { imageUrls, imageType, rootPath };
    if (GlobalSettings.isNative) {
      return new Observable(observer => {
        downloadImagesCallback(reqBody, (result: any) => {
          result = JSON.parse(result);
          observer.next(result);
        });
      });
    } else {
      return new Observable(observer => {
        if(isForObv) {
          this.downloadThumbnailOBV(imageUrls, imageType, rootPath, isForObv).pipe(first()).subscribe((response: any) => {
            observer.next(response);
          });
        }else {
          this.downloadThumbnail(observer, imageUrls, imageType, rootPath, isForObv);
        }
      });
    }
  }

  downloadThumbnailOBV(imageUrls: any, imageType: any, rootPath: any, isForObv = false) {
    let url: string = GlobalSettings.LocalNodeServerBaseUrl + '/api/download/downloadImages';
    return this.http.post<any>(url, { imageUrls, imageType, rootPath, isForObv })
      .pipe(
        tap(response => {
          return response;
        }),
        catchError(this.handleError('Downloading of images failed', []))
      );
  }

  downloadThumbnail(observer: Subscriber<any>, imageUrls: any, imageType: any, rootPath: any, isForObv = false) {
    const worker = new Worker(new URL('./downloadImages.worker', import.meta.url));
    worker.onmessage = ({ data }) => {
      if (data === true) {
        observer.next({ isCoverImageDownloaded: true });
        observer.complete();
      } else if (data.error) {
        observer.error(data.error);
      }
    };
    worker.postMessage({ imageUrls, imageType, rootPath, isForObv, url: GlobalSettings.LocalNodeServerBaseUrl + '/api/download/downloadImages' });
    return () => worker.terminate();
  }

  getDefaultThumbs(): Observable<any> {
    if (GlobalSettings.isNative) {
      var thumbnails = [];

      var lessonThumbnail = {
        'fileName': 'Lesson.png',
        'CDNPath': environment.lmsCDNPath.replace('/cdn', ''),
        'RootPath': '/useruploadedfiles/staticfiles/MEPUnitLessonDefaultThumbnails'
      };

      var unitThumbnail = {
        'fileName': 'Unit.png',
        'CDNPath': environment.lmsCDNPath.replace('/cdn', ''),
        'RootPath': '/useruploadedfiles/staticfiles/MEPUnitLessonDefaultThumbnails'
      };

      thumbnails.push(lessonThumbnail);
      thumbnails.push(unitThumbnail);
      downloadThumbnailCallback(thumbnails, (response: any) => {
        this.loggerService.logInfo('All thumbnails downloaded successfully');
      });
      return new Observable();
    } else {
      const url: string = GlobalSettings.LocalNodeServerBaseUrl + '/api/download/getdefaultthumbs';
      return this.http.post<any>(url, { env: environment.name }).
        pipe(
          tap(status => {
            return status;
          }),
          catchError(this.handleError('getDefaultThumbs', []))
        );
    }
  }

  getFileDownloadedStatus(sourceUrls: Array<string>, contentUnitID: any): Observable<FileDownloadedStatus[]> {
    if (GlobalSettings.isBrowser) {
      return of([]);
    } else {
      const url: string = GlobalSettings.LocalNodeServerBaseUrl + '/api/download/getFileDownloadedStatus';
      return this.http.post<FileDownloadedStatus[]>(url, { sourceUrls: sourceUrls, referenceId: contentUnitID })
        .pipe(
          tap(fileDownloadedStatus => {
            return fileDownloadedStatus;
          }),
          catchError(this.handleError('getFileDownloadedStatus', []))
        );
    }
  }

  getAssessmentResource(selectedCourseResources: any) {
    let isAssessmentComponents = false;
    let contentUnitID;
    for (let resource of selectedCourseResources) {
      if (resource?.resourceType?.includes('macxassess') ||
        resource?.resourceType?.includes('curassess') ||
        resource?.resourceType?.includes('nhyprimassess')) {
        contentUnitID = resource?.externalId;
        isAssessmentComponents = true;
      }
    }
    return { contentUnitID: contentUnitID, isAssessment: isAssessmentComponents };
  }

  checkAssessmentInCourses(courses: any) {
    let flag = courses.some((res: any) => res.resources.some((subItem: any) => subItem.resourceType === 'macxassess' || subItem.resourceType === 'curassess' || subItem.resourceType === 'nhyprimassess'));
    return flag;
  }

  checkEreaderInCourse(courses: any) {
    if (!courses.length) {
      return false;
    }
    let flag = courses?.every((res: any) => res.resources?.some((subItem: any) => subItem.resourceType === 'ereader'));
    return flag;
  }

  checkMeeCbCourse(courses: any) {
    if (!courses.length) {
      return false;
    }
    let flag = courses?.some(course => course?.resources?.some(resource => resource?.resourceType === "meecb"));
    return flag;
  }

  getItemsForSubMenu(checkAssessFlag: boolean, item: any) {
    if (checkAssessFlag) {
      return item;
    } else {
      let newItem = item.map((ele: any) => {
        if (ele.children) {
          let newChilddren = ele.children.filter((child: any) => child.id !== 'Assessment');
          return {
            ...ele,
            children: newChilddren
          };
        } else {
          return ele;
        }
      });
      return newItem;
    }
  }

  getAssessmentCourses(courses: any) {
    let courceArr: any = [];
    courses.forEach((course: any) => {
      if (course?.resources) {
        course?.resources.forEach((val: any) => {
          if (val.resourceType === 'curassess' || val.resourceType === 'macxassess') {
            course.contentID = val.externalId;
            courceArr.push(course)
          }
        })
      }
    })
    return courceArr
  }

  getMeeplatformCourses(courses: any) {
    let filteredCourses: any = [];
    let addedCourseIds: Set<string> = new Set();
    courses.forEach((course: any) => {
      if (course?.resources) {
        let addCourse = false;
        let pdResourceTypes = GlobalSettings.PD_RESOURCETYPES.toLowerCase().split(',');
        course.resources.forEach((val: any) => {
          if (val.resourceType.toLowerCase() === 'meeplatform') {
            filteredCourses.push(course);
          }
          if (this.data.isOfflineUser && (pdResourceTypes.includes(val.resourceType.toLowerCase())) && !addedCourseIds.has(course.subscriptionId)) {
              addCourse = true;
              addedCourseIds.add(course.subscriptionId);
          }
        });
        if (addCourse) {
          filteredCourses.push(course);
        }
      }
    });
    return filteredCourses;
  }


  createAssesmentMenu(courses: any) {
    if (courses.length == 0 && !this.isCallMenuApi) {
      this.isCallMenuApi = true;
      let url = this.baseUrl + ApiConstants.getSubscriptionDetailUrl;
      this.http.get<any>(url).subscribe((response => {
        this.createAssesmentMenu(response)
      }))
      return;
    }
    let isAssessmentCheckInCourses: any = this.checkAssessmentInCourses(courses);
    let isEreaderInCourse: any = this.checkEreaderInCourse(courses);
    let ischeckMeeCbCourse: any = this.checkMeeCbCourse(courses);
    this.handleCourseBuilder();
    switch (this.data.aceToken?.Role) {
      case 'Student':
        this.handleStudentMenu(isAssessmentCheckInCourses, isEreaderInCourse, ischeckMeeCbCourse);
        this.handleClassSetupNORoleMenu();
        break;
      case 'norole':
        this.handleNORoleMenu(); this.handleClassSetupNORoleMenu();
        break;
      default:
        this.handleNonStudentMenu(isAssessmentCheckInCourses, courses, isEreaderInCourse);
        break;
    }
  }
  private handleStudentMenu(isAssessmentCheckInCourses: any, isEreaderInCourse:any, ischeckMeeCbCourse:any) {
    this.handleNORoleMenu();
    this.data.globalNavigationMenuListStatic = this.removeStudentOrTeacherList(this.data.globalNavigationMenuListStatic, 'schoolTeacherAssessment');
    this.data.globalNavigationMenuListStatic = this.removeStudentOrTeacherList(this.data.globalNavigationMenuListStatic, 'schoolhomeworkteacher');
    this.data.globalNavigationMenuListStatic = this.removeStudentOrTeacherList(this.data.globalNavigationMenuListStatic, 'schoolGrading');
    if(this.data?.aceToken?.OrganisationName === 'MEE' && !ischeckMeeCbCourse) {
      this.data.globalNavigationMenuListStatic =  this.removeStudentOrTeacherList(this.data.globalNavigationMenuListStatic, 'coursebuilder');
    }
    if (GlobalSettings.isBrowser) {
      this.data.globalNavigationMenuListStatic = this.removeStudentOrTeacherList(this.data.globalNavigationMenuListStatic, 'schoolhomeworkapp');
      this.data.globalNavigationMenuListStatic = this.removeStudentOrTeacherList(this.data.globalNavigationMenuListStatic, 'sendreport');
    } else if (!GlobalSettings.isBrowser) {
      this.data.globalNavigationMenuListStatic = this.removeStudentOrTeacherList(this.data.globalNavigationMenuListStatic, 'schoolHomework');
      if (GlobalSettings.isDesktop) {
        this.data.globalNavigationMenuListStatic = this.removeStudentOrTeacherList(this.data.globalNavigationMenuListStatic, 'sendreport');
      }
    }
    if (isAssessmentCheckInCourses) {
      const isAssessmentMenuVisible = this.data.aceToken?.Role === 'Student';
      const globalMenuList = this.getItemsForSubMenu(isAssessmentMenuVisible, this.data.globalNavigationMenuListStatic);
      this.data.globalNavigationMenuListStatic = globalMenuList;
    } else {
      this.data.globalNavigationMenuListStatic = this.removeStudentOrTeacherList(this.data.globalNavigationMenuListStatic, 'schoolAssessment');
    }
    if (isEreaderInCourse) {
      if (GlobalSettings.isBrowser) {
        this.data.globalNavigationMenuListStatic = this.removeStudentOrTeacherList(this.data.globalNavigationMenuListStatic, 'schoolHomework');
        this.data.globalNavigationMenuListStatic = this.removeStudentOrTeacherList(this.data.globalNavigationMenuListStatic, 'schoolAssessment');
        this.data.globalNavigationMenuListStatic = this.removeStudentOrTeacherList(this.data.globalNavigationMenuListStatic, 'sendreport');
      } else if (!GlobalSettings.isBrowser) {
        this.data.globalNavigationMenuListStatic = this.removeStudentOrTeacherList(this.data.globalNavigationMenuListStatic, 'schoolhomeworkapp');
        this.data.globalNavigationMenuListStatic = this.removeStudentOrTeacherList(this.data.globalNavigationMenuListStatic, 'schoolAssessment');
        this.data.globalNavigationMenuListStatic = this.removeStudentOrTeacherList(this.data.globalNavigationMenuListStatic, 'schoolProgress');
        if (GlobalSettings.isDesktop) {
          this.data.globalNavigationMenuListStatic = this.removeStudentOrTeacherList(this.data.globalNavigationMenuListStatic, 'sendreport');
        }
      }
    }
    this.data.globalNavigationMenuList.emit(this.data.globalNavigationMenuListStatic);
  }
  private handleNonStudentMenu(isAssessmentCheckInCourses: any, courses: any, isEreaderInCourse: any) {
    this.handleClassSetupNORoleMenu();
    this.handleNORoleMenu();
    this.data.globalNavigationMenuListStatic = this.removeStudentOrTeacherList(this.data.globalNavigationMenuListStatic, 'schoolAssessment');
    this.data.globalNavigationMenuListStatic = this.removeStudentOrTeacherList(this.data.globalNavigationMenuListStatic, 'schoolHomework');
    this.data.globalNavigationMenuListStatic = this.removeStudentOrTeacherList(this.data.globalNavigationMenuListStatic, 'schoolhomeworkapp');
    this.data.globalNavigationMenuListStatic = this.removeStudentOrTeacherList(this.data.globalNavigationMenuListStatic, 'coursebuilder');
    if (GlobalSettings.isDesktop || GlobalSettings.isBrowser) {
      this.data.globalNavigationMenuListStatic = this.removeStudentOrTeacherList(this.data.globalNavigationMenuListStatic, 'sendreport');
    }
    if (courses && courses.length === 0) {
      this.data.globalNavigationMenuListStatic = this.removeStudentOrTeacherList(this.data.globalNavigationMenuListStatic, 'schoolTeacherAssessment');
      this.data.globalNavigationMenuListStatic = this.removeStudentOrTeacherList(this.data.globalNavigationMenuListStatic, 'schoolAssessment');
      this.data.globalNavigationMenuListStatic = this.removeStudentOrTeacherList(this.data.globalNavigationMenuListStatic, 'schoolhomeworkteacher');
    }
    if(!this.hasMacxpdResource(courses)){
      this.data.globalNavigationMenuListStatic = this.removeStudentOrTeacherList(this.data.globalNavigationMenuListStatic, 'schoolhomeworkteacher');
    }
    if (!isAssessmentCheckInCourses) {
      const foundItem = this.findMenuItem(this.data.globalNavigationMenuListStatic, 'schoolTeacherAssessment');
      if (foundItem) { foundItem.isHide = true }
      const foundItem2 = this.findMenuItem(this.data.globalNavigationMenuListStatic, 'schoolAssessment');
      if (foundItem2) { foundItem2.isHide = true }
    }
    if (isEreaderInCourse) {
      this.data.globalNavigationMenuListStatic = this.removeStudentOrTeacherList(this.data.globalNavigationMenuListStatic, 'schoolhomeworkteacher');
      this.data.globalNavigationMenuListStatic = this.removeStudentOrTeacherList(this.data.globalNavigationMenuListStatic, 'schoolHomework');
      this.data.globalNavigationMenuListStatic = this.removeStudentOrTeacherList(this.data.globalNavigationMenuListStatic, 'schoolhomeworkapp');
      this.data.globalNavigationMenuListStatic = this.removeStudentOrTeacherList(this.data.globalNavigationMenuListStatic, 'schoolProgress');
      this.data.globalNavigationMenuListStatic = this.removeStudentOrTeacherList(this.data.globalNavigationMenuListStatic, 'schoolGrading');
    }
    this.data.globalNavigationMenuList.emit(this.data.globalNavigationMenuListStatic);
  }

  hasMacxpdResource(courses) {
    const resourceTypes = Object.values(hwResourceTypes);
    return courses.some(subscription => subscription.resources.some(resource => resourceTypes.includes(resource.resourceType)));
}

  findMenuItem(items: any, targetId: any) {
    for (const item of items) {
      if (item.id === targetId) {
        return item;
      }
      if (item.children) {
        const foundInChildren: any = this.findMenuItem(item.children, targetId);
        if (foundInChildren) {
          return foundInChildren;
        }
      }
    }
    return null;
  }

  removeStudentOrTeacherList(item: any, id: string) {
    let newItem = item.map((ele: any) => {
      if (ele.children) {
        let newChilddren = ele.children.filter((child: any) => child.id !== id);
        return {
          ...ele,
          children: newChilddren
        };
      } else {
        return ele;
      }
    });
    return newItem;
  }

  getEplannerUserSubscriptionDetails(): Observable<any> {
    let url: string = this.baseUrl + ApiConstants.getEplannerUserSubscriptionDetails;
    return this.http.get(url)
      .pipe(
        tap(response => {
          return response;
        }),
        catchError(this.handleError('getEplannerUserSubscriptionDetails', []))
      );
  }

  getTestgeneratorRedirectionUrl(contentUnitId:any): Observable<any> {
    let url: string = this.baseUrl + ApiConstants.getTestgeneratorRedirectionUrl+`?contentUnitId=${contentUnitId}`;
    return this.http.post<any>(url,null)
      .pipe(
        tap(response => {
          return response;
        }),
        catchError(this.handleError('getTestgeneratorRedirectionUrl', []))
      );
  }  

  handleNORoleMenu() {
    let modifiedMenuList = JSON.parse(JSON.stringify(this.data.globalNavigationMenuListStatic));
    const noRoleObject = modifiedMenuList.find((item: { id: string; }) => item.id === 'School');
    if (noRoleObject) {
      if (this.data.aceToken?.Role.toLocaleLowerCase() === 'norole') {
        noRoleObject.isHide = true;
      }
      else {
        noRoleObject.isHide = false;
      }
    }
    this.data.globalNavigationMenuListStatic = modifiedMenuList;
    if (GlobalSettings.isDesktop || GlobalSettings.isBrowser) {
      this.data.globalNavigationMenuListStatic = this.removeStudentOrTeacherList(this.data.globalNavigationMenuListStatic, 'sendreport');
    }
    this.data.globalNavigationMenuList.emit(this.data.globalNavigationMenuListStatic);
  }

  handleClassSetupNORoleMenu(){
    let modifiedMenuList = JSON.parse(JSON.stringify(this.data.globalNavigationMenuListStatic));
    const adminClassObject = modifiedMenuList.find((item: { id: string; }) => item.id === 'adminCalss');
    if (adminClassObject) {
      if (this.data.aceToken?.Role.toLocaleLowerCase() === 'norole' || this.data.aceToken?.Role.toLocaleLowerCase() === 'student') {
        adminClassObject.isHide = true;
      } else {
        adminClassObject.isHide = false;
      }
    }
    this.data.globalNavigationMenuListStatic = modifiedMenuList;
    this.data.globalNavigationMenuList.emit(this.data.globalNavigationMenuListStatic);
  }

  handleCourseBuilder() {
    let modifiedMenuList = JSON.parse(JSON.stringify(this.data.globalNavigationMenuListStatic));
    const courseBuilderObject = modifiedMenuList.find((item: { id: string; }) => item.id === 'coursebuilder');
    if (courseBuilderObject) {
      courseBuilderObject.isHide = true;
    }
    this.data.globalNavigationMenuListStatic = modifiedMenuList;
    this.data.globalNavigationMenuList.emit(this.data.globalNavigationMenuListStatic);
  }

  downloadOfflineApp(reqBody){
    const url: string = GlobalSettings.LocalNodeServerBaseUrl + '/api/download/offlineApp';
    const req = {
      url:reqBody.url,
      startDate:reqBody.startDate,
      endDate:reqBody.endDate,
      selectedCourses:reqBody.selectedCourses
    }
    return this.http.post<any>(url,req)
      .pipe(
            tap(response => response),
            catchError(this.handleError('downloadOfflineApp', []))
          );
  }

  detectUsb(){
    const url: string = GlobalSettings.LocalNodeServerBaseUrl + '/api/download/detectUsb';
    return this.http.get<any>(url)
    .pipe(
          tap(response => response),
          catchError(this.handleError('detectUsb', []))
        );
  }

  copyToUsb(path: string, id: string): Observable<any> {
    let url: string = GlobalSettings.LocalNodeServerBaseUrl + '/api/download/copyToUsb';
    return this.http.post<any>(url, { 'usbPath': path })
      .pipe(
        tap(response => response),
        catchError(this.handleError('copyToUsb'))
      );
  }

  checkDiskSpace(body): Observable<any> {
    let url: string = GlobalSettings.LocalNodeServerBaseUrl + '/api/download/checkFreeDiskSpace';
    let params = new HttpParams().set('fileSize', body.fileSize).set('packageType', body.packageType);
    return this.http.get<any>(url, {params})
      .pipe(
        tap(response => response),
        catchError(this.handleError('checkFreeDiskSpace', false))
      );
  }

  replaceSubscriptionImageLocalPath(subscriptions) {
    let localPath = GlobalSettings.LocalNodeServerStaticBaseUrl + '/serveFile' + this.data.userUploadFilesPath;
    subscriptions.map((course) => {
      if (course.primaryImageUrl && !course.primaryImageUrl.includes('localhost')) {
        course.primaryImageUrl = course.primaryImageUrl.replace(/^.*\/\/[^\/]+/, localPath);
        course.secondaryImageUrl = course.secondaryImageUrl ? course.secondaryImageUrl.replace(/^.*\/\/[^\/]+/, localPath) : null
        course.resources.forEach((booksObject) => {
          booksObject.primaryImageUrl = booksObject.primaryImageUrl ? booksObject.primaryImageUrl.replace(/^.*\/\/[^\/]+/, localPath) : null;
          booksObject.secondaryImageUrl = booksObject.secondaryImageUrl ? booksObject.secondaryImageUrl.replace(/^.*\/\/[^\/]+/, localPath) : null;
        })
      }
    })
    return subscriptions;
  }

  checkURLInCurrentUrl(currentUrl: string, checkURLs: string[]): boolean {
    return checkURLs.some(url => currentUrl.includes(url));
  }

  checkCourse(courses: any,externalId: any) {
    if (!externalId) {
      return true;
    }
    let flag = courses?.some(course => course?.resources?.some(resource => resource?.externalId === externalId));
    return flag;
  }

  mac25Coursedisable(aceToken:any,currentUrl:any,contentID?:any){
    let token_key:any;
    let checkURL = ['/bookviewer/','/extra-practices/','/challenge/'];
    token_key = this.cookieService.get('token_key');
    const cookie_name = this.cookieService.get('isPageCookieActive');
     this.getCourses(aceToken?.MEEUserId,cookie_name, token_key,this.data?.clearCacheData).pipe(first()).subscribe(response => {
       if (response) {
         this.data.courses = JSON.parse(JSON.stringify(response));
         let ischeckMeeCbCourse: any = this.checkMeeCbCourse(this.data?.courses);
         if(this.checkURLInCurrentUrl(currentUrl,checkURL) && !this.checkCourse(this.data?.courses,contentID)){
          this.data.isLoading.next(false);
          this.data.isLoadingLgoin.next(false);
          this.data.isLoadingKmli.next(false);
          this.data.isLogoutTrigger.next(true);
          return;
         }
         if(!ischeckMeeCbCourse && aceToken?.Role?.toLocaleLowerCase() === 'student'){
          this.data.globalNavigationMenuListStatic =  this.removeStudentOrTeacherList(this.data?.globalNavigationMenuListStatic, 'coursebuilder');
          this.data.globalNavigationMenuList.emit(this.data.globalNavigationMenuListStatic);
        }
       }
     });
  }
}
