import { GlobalSettings } from "src/app/global.settings";
import { environment } from "src/environments/environment";
export class ApiConstants {
    public static centralizedLoginUrl = 'oidcintegration/getresponseurl';
    public static machineIdentifierUrl = GlobalSettings.isCoreApi ? 'coreplatform/getmachineidentifier' : 'account/getmachineidentifier';
    public static getRCFManifestUrl = GlobalSettings.isCoreApi ? 'manifest/getrcfmanifest' : 'pbf/getcoursecontent';
    public static getrcfmanifestforcoursebuilder = GlobalSettings.isCoreApi ? 'manifest/getrcfmanifestforcoursebuilder' : 'pbf/getcoursecontent';
    public static getAssessmentUrl = GlobalSettings.isCoreApi ? '' : '/assignment/getassignmentlist';
    public static getTocManifestUrl = GlobalSettings.isCoreApi ? 'manifest/gettocmanifest' : 'Product/gettocmanifest';
    public static getSubscriptionDetailUrl = GlobalSettings.isCoreApi ? 'subscription/getsubscriptiondetails' : 'account/getsubscriptiondetails';
    public static getHotSpotManifestUrl = GlobalSettings.isCoreApi ? 'manifest/gethotspotmanifest' : 'Product/gethotspotmanifest';
    public static saveUpdateBookmarkNotesUrl = GlobalSettings.isCoreApi ? 'bookviewer/saveorupdatebookmarknotes' : 'bookviewer/SaveOrUpdateBookmarkNotes';
    public static getBookmarkNotesUrl = GlobalSettings.isCoreApi ? 'bookviewer/getbookmarknotesdata' : 'bookviewer/GetBookmarkNotesData';
    public static deleteBookmarkNotesUrl = GlobalSettings.isCoreApi ? 'bookviewer/deletebookmarknotesdata' : 'bookviewer/DeleteBookmarkNotesData';
    public static saveUpdateIwbToolsUrl = GlobalSettings.isCoreApi ? 'bookviewer/saveorupdateiwbannotations' : 'bookviewer/SaveOrUpdateIWBannotations/';
    public static getIwbToolsUrl = GlobalSettings.isCoreApi ? 'bookviewer/getiwbannotations' : '/bookviewer/GetIWBAnnotations';
    public static authUrl = GlobalSettings.isCoreApi ? environment.authCoreUrl : environment.authUrl;
    public static aceApiBaseUrl = GlobalSettings.isCoreApi ? environment.aceApiBaseCoreUrl : environment.aceApiBaseUrl;
    public static printPdfBaseUrl = GlobalSettings.isCoreApi ? environment.printPdfBaseUrl : environment.printPdfBaseUrl;
    public static macmillanEducationUrl = environment.macmillanEducationUrl;
    public static siteUrl = environment.siteUrl;
    public static languageCdnUrl = environment.languageCdnUrl;
    public static deleteIwbToolsUrl = GlobalSettings.isCoreApi ? 'bookviewer/deleteiwbannotation' : 'bookviewer/DeleteIWBAnnotation';
    public static applicationBuildVersion = GlobalSettings.isCoreApi ? 'Account/getapplicationbuildversion' : 'Account/getapplicationbuildversion';
    public static meeTokenUrl = GlobalSettings.isCoreApi ? 'coreplatform/getmeetoken' : 'account/getMeeToken';
    public static saveupdatebooklastaccess = GlobalSettings.isCoreApi ? 'bookviewer/saveupdatebooklastaccess' : 'bookviewer/saveupdatebooklastaccess';
    public static getbooklastaccess = GlobalSettings.isCoreApi ? 'bookviewer/getbooklastaccess' : 'bookviewer/getbooklastaccess';
    public static downloadAppUrl = GlobalSettings.isCoreApi ? 'utility/downloadapp' : 'ACEAPI/PdfExport/downloadapp';
    public static setRedisData = GlobalSettings.isCoreApi ? 'utility/setrediscachefe' : 'utility/setrediscachefe';
    public static getRedisData = GlobalSettings.isCoreApi ? 'utility/getrediscachefe' : 'utility/getrediscachefe';
    public static clearRedisData = GlobalSettings.isCoreApi ? 'utility/deleterediscachefe' : 'utility/deleterediscachefe';
    public static deleteCacheData = GlobalSettings.isCoreApi ? 'utility/deletecache' : 'utility/deletecache';
    public static savehotcornerselectionUrl = GlobalSettings.isCoreApi ? 'coreplatform/savehotcornerselection' : 'account/savehotcornerselection';
    public static gethotcornerselectionUrl = GlobalSettings.isCoreApi ? 'coreplatform/gethotcornerselection' : 'account/gethotcornerselection';
    public static createOrUpadateCalendarEventUrl = 'calendar/createupdatecalendarevent';
    public static getCalendarEventsUrl = 'calendar/getcalendareventdetails';
    public static getattendeesUrl = 'calendar/getattendees';
    public static deleteCalendarEventsUrl = 'calendar/deletecalendareventdetails';
    public static getuserbasicdetailsUrl = 'thirdpartyintegration/getuserbasicdetails';
    public static getzoomuserbasicdetailsUrl = 'thirdpartyintegration/getzoomuserbasicdetails';
    public static createmeetingUrl = "thirdpartyintegration/createmeeting";
    public static getredirectUrl = 'thirdpartyintegration/getredirecturl';
    public static getuserslistsforrecipient = 'coreplatform/getuserslistsforrecipient';
    public static getdomainclassesUrl = 'institutionintegration/getdomainclasses';
    public static getdomainclassesforgradingUrl = 'grading/getdomainclassList';
    public static getclassesSubgroupsUrl = 'institutionintegration/getclasssubgroups';
    public static saveHomeworkAssessmentUrl = 'homeworkassessment/addupdatehomeworkassessment';
    public static createCourseContent = 'homeworkassessment/createCourseContent';
    public static createUpdateCourseContent = 'homeworkassessment/upsertCourseContent';
    public static getactivityquestioncountUrl = 'homeworkassessment/getactivityquestioncount';
    public static getactivityquestionListUrl = 'homeworkassessment/getactivitylisting';
    public static getCourseBuilderListUrl = 'homeworkassessment/getcoursebuilderlist';
    public static getPoolPartyDataUrl = 'homeworkassessment/getpoolpartydata';
    public static setRedis = 'utility/setrediscache';
    public static getRedis = 'utility/getrediscache';
    public static deleteRedis = 'utility/deleterediscache';
    public static uploadAudioFile = 'utility/uploadaudiofile';
    public static getLastAccessedActivity = 'progress/getlastaccessedactivity';
    public static getAnswerForActivitySet = 'progress/getanswerforactivityset';
    public static setAnswerForActivitySet = 'progress/setanswerforactivityset';
    public static saveUpdateLastAccessedActivity = 'progress/saveupdatelastaccessedactivity';
    public static challengeActivityInfo = 'progress/getchallengeactivityinfo';
    public static getCompletionActivityStatus = 'progress/getcompletionactivitystatus';
    public static getGetTrophyDetails = 'progress/gettrophydetails';
    public static getClassStudentsComponentHaCountUrl ='progresstracker/getclassstudentscomponenthasummary';
    public static getClassesStudentsSubgroupsSubscriptionDetailsUrl = 'homeworkassessment/getclassesstudentssubgroupssubscriptiondetails';
    public static getCurrentUserUrl = 'coreplatform/getcurrentuser';
    public static addEditUgcQuestionDetailsUrl = 'homeworkassessment/addeditugcquestiondetails';
    public static getHomeworksAssessmentListUrl = 'homeworkassessment/gethomeworkasssessmentlist';
    public static setShowHideActivityUrl = 'homeworkassessment/setshowhideactivity';
    public static getComponentTreeStructureUrl = 'homeworkassessment/getcomponenttreestructure';
    public static getCheckQuestionbankAndPBAavailabilityUrl = 'homeworkassessment/checkquestionbankandpbaavailability';
    public static getHomeworkAssessmentDetailsUrl = 'homeworkassessment/gethomeworkassessmentdetails';
    public static getCourseContentListUrl = 'homeworkassessment/getcoursecontentlist';
    public static getPBAfolderStructureUrl = 'homeworkassessment/getpbafolderstructure';
    public static getHomeworkAssessmentListUrl = 'homeworkassessment/gethomeworkasssessmentlist';
    public static getClassGradingSummaryUrl = 'grading/getclassgradingsummary';
    public static getClassGradingDetailsUrl = 'grading/getclassgradingdetails';
    public static getCompletedBubbleCountUrl = 'grading/getcompletedbubblecount';
    public static getCompletedGradingSummaryUrl = 'grading/getcompletedgradingsummary';
    public static getRequiresGradingSummaryUrl = 'grading/getrequiresgradingsummary';
    public static getRequiresGradingBubbleCountUrl = 'grading/getrequiresgradingbubblecount';
    public static transformugctorcfUrl = 'homeworkassessment/transformugctorcf';
    public static transformUgctoRcfManifestUrl = 'homeworkassessment/transformugctorcfmanifest';
    public static courseWiseStudentProgressSummary = 'progresstracker/getcoursewisestudentprogresssummary';
    public static getStudentGradingSummaryUrl = 'grading/getactivitywisestudentsgradingsummary';
    public static getPaperAssessmentGradingQuestions = 'grading/getpaperassessmentgradingquestions';
    public static savePaperAssessmentGradingQuestions = 'grading/savepaperassessmentgrading';
    public static getStudentGradingDetailsUrl = 'grading/getactivitywisestudentsgradingdetails';
    public static getStudentHomeworkAssessmentListUrl = 'homeworkassessment/getstudenthomeworkassessmentlist';
    public static classWiseStudentProgressSummary = 'progresstracker/getclasswisestudentprogresssummary';
    public static updateControlledStartForAssessmentUrl = 'homeworkassessment/updatecontrolledstartforassessment';
    public static getstudenthomeworkassessmentdetailsUrl = 'homeworkassessment/getstudenthomeworkassessmentdetails';
    public static setstudentactivitydetailsUrl = 'homeworkassessment/setstudentactivitydetails';
    public static classWiseStudentsPerformanceSummary = 'progresstracker/getclasswisestudentsperformancesummary';
    public static classWiseClassworkPerformanceSummary = 'progresstracker/getclasswiseclassworkperformancesummary';
    public static addUpdateStudentsComponentFeedback = 'coreplatform/addupdatestudentscomponentfeedback';
    public static addUpdateStudentFeedback = 'grading/addupdatestudenthafeedback';
    public static classAdmin = 'coreplatform/redirecttoinstitutionmanagementapp';
    public static validateJoiningCode = 'management/validatejoiningcode';
    public static joinClassOrInstitute = 'management/joinclassorinstitute';
    public static getclasssubgroupdetails = 'management/getclasssubgroupdetails';
    public static copyAssessmentUrl = 'homeworkassessment/copyhomeworkassessment';
    public static deleteHomeworkAssessmentUrl = 'homeworkassessment/deletehomeworkassessment';
    public static saveDeviceToken = 'notifications/saveupdateuserdevicetokendetails';
    public static getGradingClassCoursesDropdownUrl = 'grading/getcoursesbyclassId';
    public static getNotificationsList = 'notifications/getusernotifications';
    public static getnotificationcountUrl = 'notifications/getuserunreadnotificationscount';
    public static viewNotificationUrl = 'notifications/markusernotificationasread';
    public static deleteuserdevicetoken = 'notifications/deleteuserdevicetoken';
    public static getNotificationDeleteUrl = 'notifications/deletenotificationdetails';
    public static checkpapertestprogressstatusUrl = 'homeworkassessment/checkpapertestprogressstatus';
    public static getuserslatestnotifications = "notifications/getuserslatestnotifications";
    public static classWiseClassworkCStudentPerformanceDetails = 'progresstracker/getclasswiseclassworkstudentperformancedetails';
    public static studentWiseActivityPerformanceDetailsStudentView ='progresstracker/getstudentwiseactivityperformancedetailsstudentview';
    public static getStudentActivityDetailsByActivitySetIdUrl = 'homeworkassessment/getStudentActivityDetailsByActivitySetId';
    public static getugcrequestjsonUrl = 'homeworkassessment/getugcrequestjson';
    public static generatePaperTestArtifactUrl = 'homeworkassessment/generatepapertestartifact';
    public static printPdfNewUrl = 'print/generatepapertestartifact';
    public static getrcfplayabledataUrl = 'homeworkassessment/getrcfplayabledata';
    public static userWiseActivityWiseAttemptDetails = 'progresstracker/getuserwiseactivitywiseattemptdetails';
    public static getClassAverageForSelectedActivity = 'progresstracker/getclassaverageforselectedactivity';
    public static getHomeworkAssessmentStudentsGradingSummaryUrl = 'grading/GetHomeworkAssessmentStudentsGradingSummary';
    public static getHomeworkAssessmentStudentsGradingDetailsUrl = 'grading/gethomeworkassessmentstudentsrequiredgradingdetails';
    public static getHomeworkAssessmentStudentsPendingDetailsUrl = 'grading/gethomeworkassessmentstudentspendinggradingdetails';
    public static getHomeworkAssessmentStudentsExemptedDetailsUrl = 'grading/getHomeworkAssessmentStudentsExemptedDetails';
    public static getComponentClassAverage = 'progresstracker/getcomponentclassaverage';
    public static getComponentClassAverageByStudent = 'progresstracker/getcomponentclassaverageByStudent';
    public static getFeedbackMessageUrl = 'coreplatform/getstudentscomponentfeedback';
    public static getStudentHomeworkAssessmentFeedbackUrl = 'grading/getstudenthafeedback';
    public static getHomeworkAssessmentStudentsCompletedDetailsUrl = 'grading/gethomeworkassessmentstudentscompletedgradingdetails';
    public static getClassAverageForSelectedLesson = 'progresstracker/GetClassAverageForSelectedLesson';
    public static getClassAverageForSelectedUnit = 'progresstracker/getclassaverageforselectedunit';
    public static getInsertModuleDetailsUrl = 'datamigration/getinsertmoduledetails';
    public static getInsertHomeworkModuleDetailsUrl = 'datamigration/getinserthomeworkmoduledetails';
    public static getInsertAssessmentModuleDetailsUrl = 'datamigration/getinsertassessmentmoduledetails';
    public static getInsertAnnotationModuleDetailsUrl = 'datamigration/getinsertannotationmoduledetails';
    public static getInsertNavioStudentAvatarGuideRewardsInfoUrl = 'datamigration/getinsertnaviostudentavatarguiderewardsinfo';
    public static updateStudentRelatedDetailsUrl = 'datamigration/updatestudentrelateddetails';
    public static getInsertHomeworkStudentModuleDetailsUrl = 'datamigration/getinserthomeworkstudentmoduledetails';
    public static unitWiseCourseworkStudentPerformanceDetails = 'progresstracker/getunitwisecourseworkstudentperformancedetails';
    public static lessonWiseCourseworkStudentPerformanceDetails = 'progresstracker/getlessonwisecourseworkstudentperformancedetails';
    public static addUpdateExemptStudentHomeworkAssessment = 'grading/addupdateexemptstudenthomeworkassessment';
    public static getIndividualAssessmentDetails = 'progresstracker/gethomeworkassessmentstudentsummarydetail';
    public static homeworkAssessmentStudentSummary = 'progresstracker/gethomeworkassessmentstudentsummary';
    public static getAssignmentStudentsList = 'progresstracker/getassignmentstudentslist';
    public static getClasswiseHomeworkAssessmentCardSummary = 'progresstracker/getclasswisehomeworkassessmentcardsummary';
    public static getStudentwiseHomeworkAssessmentCardSummary = 'progresstracker/getstudentwisehomeworkassessmentcardsummary';
    public static getinsertassessmentstudentmoduledetails = 'datamigration/getinsertassessmentstudentmoduledetails';
    public static checkcurrentusereligibilityformigration  = 'launchdarklycontroller/checkcurrentusereligibilityformigration';
    public static ssoLogout = 'oidcintegration/getlogouturl';
    public static logoutredirect = 'oidcintegration/logoutredirect';
    public static getClassAverageForAssignment = 'progresstracker/getclassaverageforassignment';
    public static checkusersfinaldatamigrationstatus = 'datamigration/checkusersfinaldatamigrationstatus';
    public static getinserthomeworkstudentremainingattemptsdetails = 'datamigration/getinserthomeworkstudentremainingattemptsdetails';
    public static getinserteplannermoduledetails = 'datamigration/getinserteplannermoduledetails';
    public static getEplannerUserSubscriptionDetails = 'eplanner/geteplannerusersubscriptiondetails';
    public static getstudentwiseclassworkperformancesummary = 'progresstracker/getstudentwiseclassworkperformancesummary';
    public static getStudentProgressDetailsByCourse = 'progresstracker/getStudentProgressDetailsByCourse';
    public static getTestgeneratorRedirectionUrl = 'coreplatform/gettestgeneratorredirectionurl';
    public static getUnitWiseCourseWorkStudentPerformanceDetailsStudentView = 'progresstracker/getunitwisecourseworkstudentperformancedetailsstudentview';
    public static getLessonWiseCourseWorkStudentPerformanceDetailsStudentView = 'progresstracker/getlessonwisecourseworkstudentperformancedetailsstudentview';
    public static updateorganizationaccountid = "management/updateorganizationaccountid";
    public static callforprocessusermigrateddata = "datamigration/callforprocessusermigrateddata";
    public static getusermigrationstatus  = "datamigration/getusermigrationstatus";
    public static getStudentsListByClassId = 'progresstracker/getstudentslistbyclassid';
    public static exportCSVClasswork = "progresstracker/exportclasswiseclassworkstudentperformancedetails";
    public static getFilterClassListUrl = 'progresstracker/getfilterclasslist';
    public static exportCSVHomework = 'progresstracker/exportgethomeworkassessmentstudentsummarydetail';
    public static exportCsvAllActivity = 'progresstracker/exportunitorlessonwisecourseworkstudentperformancedetails';
    public static exportCsvStudentSingleActivity = 'progresstracker/exportstudentwisecourseworkallactivitydetails';
    public static exportCsvAssessment = 'progresstracker/exportAssessmentStudentSummaryDetail';
    public static exportCSVHomeworkMultiple = 'progresstracker/exporthomeworkassessmentstudentsummary';
    public static exportCSVAssessmentMultiple = 'progresstracker/exportsingleassessmentallstudentsummary';
    public static exportCSVClassWiseHomeworkAssessmentCardSummary = 'progresstracker/exportclasswisehomeworkassessmentcardsummary';
    public static exportCSVStudentWiseHomeworkOrAssessment = 'progresstracker/exportstudentwisehomeworkorassessment';
    public static exportCSVUnitcourseworkStudent = 'progresstracker/exportunitwisecourseworkstudentperformancedetails';
    public static exportCSVcourseworkLessonwiseStudent = 'progresstracker/exportlessonwisecourseworkstudentperformancedetails';
    public static exportCSVClasswiseStudentProgressSummary = 'progresstracker/exportclasswisestudentprogresssummary';
    public static exportCSVcourseworkAssessmentCardSummary = 'progresstracker/exportclasswiseclassworkperformancesummary';
    public static exportCSVclasswisestudentsperformancesummary = 'progresstracker/exportgetclasswisestudentsperformancesummary';
    public static exportCSVstudentwiseclassworkperformancesummary = 'progresstracker/exportstudentwiseclassworkperformancesummary';
    public static exportCSVlessonwisecourseworkstudentperformancedetailsstudentview ='progresstracker/exportlessonwisecourseworkstudentperformancedetailsstudentview';
    public static saveStudentCourseBuilderUrl = 'homeworkassessment/addupdatecourseassignments';
    public static getThemeListUrl = 'coreplatform/getthemes';
    public static getEditprofile = 'coreplatform/editprofile';
    public static getcurrentusertheme = 'coreplatform/getcurrentusertheme';
    public static getTotalHomeworkCountForMigration = 'datamigration/gethomeworkcount';
    public static deleteAllDataForMigration = 'datamigration/deletehomeworkdata';
    public static deleteModuleDataForMigration = 'datamigration/homeworkdatadeletion';
    public static getExtLinks = 'bookviewer/getexternallinks' ;
    public static getOrgnizationName = 'coreplatform/getorganisationname' ;
    public static getallstudentslist = 'progresstracker/getallstudentslist';
    public static updateOfflineMigrationFirstLogin = 'offlinedatamigration/updateofflinemigrationfirstlogin';
    public static addupdatekmliUrl = 'coreplatform/addupdatekmli';
    public static getMachineIdentifierUrl = `${environment.baseUrl}/acecoreapi/authentication/api/v1/getmachineidentifier`;
    public static getResponseUrl = `${environment.baseUrl}/acecoreapi/authentication/api/v1/getresponseurl`;  
    public static getTokenUrl = `${environment.baseUrl}/acecoreapi/authentication/api/v1/gettoken`; 
    public static getLogOutUrl = `${environment.baseUrl}/acecoreapi/authentication/api/v1/getlogouturl`; 
}
