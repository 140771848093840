import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of, interval, Subscription } from 'rxjs';
import { catchError, first, mergeMap, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ACEToken } from '../models/token';
import { Data } from '../models/data';
import { TokenService } from './token.service';
import { GlobalSettings } from 'src/app/global.settings';
import { ApiConstants } from '../constant/api.constant';
import {SsrCookieService} from 'ngx-cookie-service-ssr';
import { IAuthLogin } from '../models/http-handler';
import { CommonService } from './common.service';
import { IpcService } from './ipc.service';
import { ToastService } from 'src/app/services/toast.service';
import { ToastMessageType, cookieCharLimit } from '../models/enum';
import { customerSupportLabel, HELP_URL} from '../models/homework';
import { BodyClassService } from './body-class.service';
import {START_NOTIFICATION_SERVICE,NOTIFICATION_SERVICE_STARTED,NOTIFICATION_RECEIVED as ON_NOTIFICATION_RECEIVED, NOTIFICATION_SERVICE_ERROR} from '@cuj1559/electron-push-receiver/src/constants';
import { LoggerService } from './logger.service';


@Injectable()
export class AuthService implements OnDestroy{
  [x: string]: any;
  baseUrl: string = ApiConstants.aceApiBaseUrl;
  getRedisurl: string = this.baseUrl + ApiConstants.getRedisData+'?key=cachetoken_'+this.cookieService.get('token_key');
  skipUserCheck = false;
  ottFlag = false;
  isOCID: string = 'false';
  count = 0;
  subscriptionList: Subscription[] = [];
  public subscription!: Subscription;
  constructor(private http: HttpClient,
    private ipcService: IpcService,
    public toastService: ToastService,
    public loggerService: LoggerService,
    private data: Data, private tokenService: TokenService,private cookieService: SsrCookieService,public commonService:CommonService, private bodyClassService: BodyClassService) {
    this.data.loginExceedMessageAcknowledge.subscribe(data => {
      this.SkipUserCheck(data);
    });
  }
  ngOnDestroy(): void {
    if (this.subscriptionList.length) {
      this.subscriptionList.forEach(subscription => {
        subscription.unsubscribe();
      });
    }
  }
  SkipUserCheck(data: any) {
    if (data && data.message) {
      this.skipUserCheck = true;
    } else {
      this.skipUserCheck = false;
    }
  }
  getRedisData(): Observable<any> {
    if (GlobalSettings.isDesktop) {
      const url: string = GlobalSettings.LocalNodeServerBaseUrl + '/api/login/getRedisAPI';
      let params: any = new HttpParams().set('key', 'cachetoken_' + this.cookieService.get('token_key')).set('keyRole', true);
      return this.http.request('GET', url, { responseType: 'json', params })
      .pipe(
        tap(response => {
          return response;
        }),
        catchError(this.handleError('getRedis', []))
      );
    } 
    else if (GlobalSettings.isNative) {
      return new Observable(observer => {
        getRedisAPICallback(this.data.tokenKey, ((res: any) => { observer.next(res); }));
      });
    } else {
      if (this.cookieService.get('token_key') && this.cookieService.get('token_key').length == cookieCharLimit) {
        return this.http.get<any>(this.getRedisurl);
      } else {
        return of(null);
      }
    }
  }
  getKmliData(): Observable<any> {
    return this.http.get<any>(this.getRedisurl+'_Kmlikey');
  }
  login(username: string, password: string, OTT?: string, machineIdentifier?: string, isLoadTest?: string, contentUnitId?: string, isOCID?: string, isSkipUserCheck?: string): Observable<any> {

    var params: Partial<IAuthLogin> | String = '';

    if (GlobalSettings.isCoreApi) {
      params = { ott: OTT, machineIdentifier: machineIdentifier};
    } else {
      if (isOCID !== undefined) {
        this.isOCID = isOCID;
      }
      if (OTT) {
        params = `OTT=${OTT}&grant_type=password&accountkey=macx&isResetPassword=0&rememberMe=false&machineIdentifier=${machineIdentifier}&isLoadTest=${isLoadTest}&isresetpassword=0&nativeappdomainflag=true&captcha=NA&isforcedlogin=false`;
        this.ottFlag = true;
      }
    }
    if (!OTT && !machineIdentifier) {
      return of();
    } else {
      return this.oAuth(params)
      .pipe(tap(response => {
        return this.Tokenresponse(response);
      }),
     catchError(this.handleError('login', [])));
    }
  }
  Tokenresponse(response: any) {
    if (response.access_token) {
      this.tokenService.setACEToken(response);
   
      if(GlobalSettings.isDesktop && response.fbc){
        this.startPushService(response);
      }
      if(GlobalSettings.isNative){
        setTimeout(() => {
          const data={token:''};
          this.subscription=this.commonService.saveToken(data).subscribe((res) => {
            //console.log("res authservice native token",res);
          });
        }, 2000);
      }

      if (!GlobalSettings.isBrowser) {
        this.scheduleTokenRefresh(response);
      }

      return true;
    }
    else if (response.error) {
      return response.error_description;
    }
  }
  oAuth(params: any): Observable<any> {
    let ClientId = GlobalSettings.MACXClientId;
    let headers: HttpHeaders;
    if (this.ottFlag) {
      headers = new HttpHeaders({
        'ClientId': ClientId.toString(),
        'browserLogInAltura': GlobalSettings.isBrowser ? 'True' : 'False',
        'skipUserCheck': 'False',
        'OCID': this.isOCID
      });
    } else {
      headers = new HttpHeaders({
        'ClientId': ClientId.toString()
      });
    }
    const tokenUrl = this.commonService.getUrl(this.data.microServiceFlags?.authentication, ApiConstants.getTokenUrl, ApiConstants.authUrl);
    if (GlobalSettings.isBrowser) {
      return GlobalSettings.isCoreApi ? this.http.post(tokenUrl, params)
        : this.http.post(ApiConstants.authUrl, params, { headers: headers })
          .pipe(
            tap(response => {
              return response;
            }),
            catchError(this.handleError('login', []))
          );
    }
    else if (GlobalSettings.isNative) {
      return new Observable(observer => {
        const authdata = GlobalSettings.isCoreApi ? {
          params: params
        } : {
          params: params,
          headers: headers
        };
        authTokenCallback(authdata, (result: string) => {
          result = JSON.parse(result);
          if (result) {
            observer.next(result);
          }
        });
      });
    } else {
      const url: string = GlobalSettings.LocalNodeServerBaseUrl + '/api/login/token';
      return this.http.post<any>(url, { params,clientId: ClientId.toString(), env: environment.name });
          tap(response => {
            console.log(`Auth Login`);
            return response;
          }),
          catchError(this.handleError('Auth Login', []));
        
    }
  }
  refreshToken(token: ACEToken) {
    return true;
  }
  checkSessionTimeoutForBrowser(token: ACEToken) {
    let tokenExipryTime = new Date(token['.expires']).getTime();
    let idleTime;
    if (token['expires_in']) {
      idleTime = Math.floor(token['expires_in'] / 60);

      let currentTime = new Date().getTime();
      let timeleft = tokenExipryTime - currentTime;
      let minutesLeft = Math.floor(timeleft / 60000);
      if (minutesLeft < idleTime / 2 && minutesLeft > 0) {
        this.refreshToken(token);
      }
    }
  }
  scheduleTokenRefresh(token: ACEToken) {
    let now = new Date();
    let intervalTime: number;
    let tokenExipryDate = new Date(token[".expires"]);
    intervalTime = (tokenExipryDate.getTime() - 5 * 60 * 1000) - now.getTime();
    this.data.scheduleTokenRefresh = interval(intervalTime).subscribe(() => {
      if (this.data.isKMLIChecked) {
        this.refreshToken(token);
      }
    });
  }

  getMasterDetails(productFrameworkId: any, MEEUserId: any): Observable<any> {
    if (GlobalSettings.isNative) {
      return new Observable(observer => {
        getMasterDetailsCallback(productFrameworkId, ((res: any) => {
          observer.next(res);
        }));
      });
    } else if (GlobalSettings.isDesktop) {
      const url: string = GlobalSettings.LocalNodeServerBaseUrl + '/api/account/masterdetails';
      let params = new HttpParams().set('productFrameworkId', productFrameworkId).set('meeUserId', MEEUserId).set('env', environment.name);
      return this.http.request('GET', url, { responseType: 'json', params })
        .pipe(
          tap(response => {
            return response;
          }),
          catchError(this.handleError('masterdetails', []))
        );
    } else {
      return new Observable();
    }
  }
  redirectUrl(url:string){
    this.cookieService.set('urlBeforeRedirection', url, { expires: 900000, path: '/' });
}
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      return of(result as T);
    };
  }

  startPushService(data:any) {
    this.commonService.isLoading_show();
    this.ipcService.on(NOTIFICATION_SERVICE_STARTED, (_: any, token: any) => {
      this.data.FMCtoken=token;
      const data={token:token};
      this.subscription=this.commonService.saveToken(data).subscribe((res) => {
        this.commonService.isLoading_hide();
      });
    });
    this.ipcService.on(NOTIFICATION_SERVICE_ERROR, (_, error) => {
      this.loggerService.logError(`Error in notification: ${error}`)
    });
    this.ipcService.on(ON_NOTIFICATION_RECEIVED, (_ :any, serverNotificationPayload:any) => {
        if (serverNotificationPayload.notification.body){
        let rUrl = serverNotificationPayload.data.click_action;
        if(rUrl === GlobalSettings.escapeUrl){
          rUrl = GlobalSettings.escapeRedirectPath;
        }
        new Notification(serverNotificationPayload.notification.title, { 
          body: serverNotificationPayload.notification.body,
          data:rUrl,
          icon: '/favicon.ico',
        }).onclick = (event:any) => {
        this.commonService.redirectUrl(rUrl);
      }; 
      }
      
    });
    let _fbc = this.commonService.parsefbcString(data.fbc);
    const senderId = _fbc.messagingSenderId ;
    // Using HTTP v1 compliant library: https://github.com/Choiman1559/electron-push-receiver
    this.ipcService.send(START_NOTIFICATION_SERVICE, _fbc.appId, _fbc.projectId, _fbc.apiKey, _fbc.vapidKey);
 
  }
  updateorganizationaccountid() {
    const url: string = `${this.baseUrl}${ApiConstants.updateorganizationaccountid}`;
    this.http.post<any>(url, {}).pipe(first()).subscribe(
      (response) => {
        const accountKeyNew = response.accountKey.toLocaleLowerCase();
        const accountKeyOld =this.data.aceToken?.AccountKey.toLocaleLowerCase();
        const { meeAccountId, organisationAccountId, accountKey, organisationName, accessToken, refreshToken } = response;
        let tempTokenData = this.data.aceToken as ACEToken
        tempTokenData.AccountKey = accountKey;
        tempTokenData.OrganisationName = organisationName;
        tempTokenData.refresh_token = refreshToken;
        tempTokenData.access_token = accessToken;
        tempTokenData.MEEUserId = meeAccountId;
        tempTokenData.InstitutionID = organisationAccountId;
        this.data.aceToken = tempTokenData;  
        if (GlobalSettings.isBrowser) {
            let redisUrl: string = this.baseUrl + ApiConstants.setRedis;
            const redisData = {
              key: 'cachetoken_'+this.cookieService.get('token_key'),
              value: tempTokenData,
              cacheValidity: 21600
            }; 
            this.http.post<any>(redisUrl, redisData).subscribe(dataset => {});
        } else {
            this.setRedis_apps(tempTokenData).pipe(first()).subscribe(() => {});
        }
        if (accountKeyNew && accountKeyNew !== accountKeyOld) {
          this.data.organizationIDUpdated.next(true);
          this.datamigrationAPICall();
        }
      },
      (error) => {
        console.error('Error:', error);
      }
    );
  }
  callforprocessusermigrateddata(): Observable<any> {
    let callforprocessusermigrateddata_Url = this.baseUrl + ApiConstants.callforprocessusermigrateddata;
    const body = {};
    return this.http.post(callforprocessusermigrateddata_Url,body);
  }
  datamigrationAPICall(retryCount: number = 1): void {
    this.data.ispopupLoading.emit(true);   
    let getusermigrationstatus_Url = this.baseUrl + ApiConstants.getusermigrationstatus;
    if(retryCount == 1){
      this.callforprocessusermigrateddata().subscribe(res => {
        console.log('Success')
      });
    }
    this.http.get(getusermigrationstatus_Url).subscribe(
      (response : any) => {
        this.data.ispopupLoading.emit(true);
        if (response.status == "InProgress" && retryCount < 4) {
          setTimeout(() => {
            this.datamigrationAPICall(retryCount + 1);
          }, 30000);
        } else if(response.status == "Error") {
         this.data.ispopupLoading.emit(false);
         this.bodyClassService.addBodyClass('show-toast');
         const link = { label: this.data.languageJson?.classManagement?.ccsLabel || customerSupportLabel.ccsLabel , hyperLink: HELP_URL }
         this.toastService.showMessage(ToastMessageType.ERROR,this.data.languageJson?.classManagement?.apiErrorMsg,false,link);       
        } else {      
         this.data.ispopupLoading.emit(false);
         this.data.ispopupLoadingSuccess.emit(true);       
       }
      },
      (error) => {
        this.data.ispopupLoading.emit(false);
        console.error('Error:', error);
      }
    );
  }
  setRedis_apps(tokenResponse: any): Observable<any> {
    if (GlobalSettings.isNative) {
      return new Observable(observer => {
        setRedisCacheCallback(tokenResponse, ((responseOfSetRedis: any) => {
          responseOfSetRedis = JSON.parse(responseOfSetRedis);
          observer.next(responseOfSetRedis.result);
        }));
      });
    } else {
      const url: string = GlobalSettings.LocalNodeServerBaseUrl + '/api/login/setRedis';
      return this.http.post<any>(url, { token: tokenResponse, env: environment.name })
        .pipe(
          tap(response => {
            return response;
          }),
          catchError(this.handleError('setRedis', []))
        );
    }
  }
}