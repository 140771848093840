import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Inject, Input, OnChanges, OnDestroy, OnInit, Output, PLATFORM_ID, SimpleChanges, ViewEncapsulation, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Data } from 'src/app/core/models/data';
import { delay, filter, first, of, Subject, Subscription, takeUntil, takeWhile } from 'rxjs';
import { MenuList } from './data';
import { GtmService } from 'src/app/core/services/gtm.service';
import { VIEW_COURSE_CONTENT, GTMEvents,Settings } from 'src/app/core/models/gtm-models/gtm';
import { LanguageExtService } from 'src/app/services/language-ext.service';
import { GlobalSettings } from 'src/app/global.settings';
import { CdnAssets, dashboardTypes } from 'src/app/core/models/constants';
import { CommonService } from 'src/app/core/services/common.service';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { CourseService } from 'src/app/core/services/course.service';
import { environment } from 'src/environments/environment';
import { EVENT_CATEGORY, GlobalEmptyImgTheme,ToastMessageType, getdeviceType } from 'src/app/core/models/enum';
import { AccountService } from 'src/app/core/services/account.service';
import { TokenService } from 'src/app/core/services/token.service';
import { UtilityService } from 'src/app/services/utility.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-global-navigation',
  templateUrl: './global-navigation.component.html',
  styleUrls: ['./global-navigation.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GlobalNavigationComponent
  implements OnDestroy, OnChanges, OnInit, AfterViewInit {
  @Input() showMenu = false;
  @Output() cascadeOpened = new EventEmitter();
  @Output() cascadeThemeOpened = new EventEmitter();
  isShowMenubar = false;
  isShowCascadebar = false;
  isShowGlobalmenu = false;
  menuList = MenuList;
  cascadeMenus: any[] = [];
  title = 'Welcome';
  subTitle = 'Overline';
  backUrl = './';
  logoUrl = '/';
  logo =
    GlobalSettings.routePath + '/assets/default/images/logo-mee-black-mac.svg';
  smallLogo = GlobalSettings.routePath + '/assets/default/images/logo.svg?ve=4';
  logoAltText = 'Macmillan Education Everywhere Platform';
  moreMenuTitle = 'More';
  ariaLabels: any = {
    backButtonAriaLabel: 'Back',
    closeButtonAriaLabel: 'Close',
    hamburgerAriaLabel: 'Menu',
    logoIcon: 'Logo',
  };
  activeHamburger = false as boolean;
  currentUrl = '' as string;
  buttonType = 'button' as string;
  buttonId = 'hamburgerIcon' as string;
  buttonValue = 'hamburger' as string;
  focusableElement:any;

  subscriptionList: Subscription[] = [];
  dummyId6: string = 'dummyId6';
  dummyId7: string = 'dummyId7';
  list = [
    { key: 'Start Date', value: '21/05/20' },
    { key: 'End Date', value: '22/06/20' },
  ];
  theamUrl:any ='';
  subActive = true;
  showTitle = false;
  showOverlay: boolean = false;
  isJS: boolean = false;
  cascadeMenubarLabel: string = '';
  activeMenuHierarchy: any;
  focusableElements: Array<HTMLElement> = [];
  isCascadeThemeOpens = false as boolean;
  themeMenu = { id: 'Blue', class: 'blue', hierarchyLevel: '4_3', name: 'Blue', hasTheme: true, url: 'sub1', queryParams: { themeId: 'Blue' }, themeType: "Basic" };
  hasThemeOption = false as boolean;
  hasBackButton = true as boolean;
  optInStatusIcon= 'icon-check' as string;
  isThemeMenubarExpand = false as boolean;
  WindowMaxWidth = 1024;
  GlobalNavidationValue: string = '';
  ctrlKeyPressed = false;
  equalKeyPressed = false;
  ctrlKeyUp = false;
  minusKeyPressed = false;
  showHamburger = false;
  backBtn:  string = '';
  menuBtn:  string = '';
  KidsthemeLabel: string = '';
  themeSelectionTitleLabel: string = '';
  private destroySubscription = new Subject<void>();
  private destroy$: Subject<boolean> = new Subject();
  previousUrl: string = '';
  isInternetAvailable:boolean = true;
  isOnline: boolean;
  subscriptionTheme: any;
  subscription!: Subscription;
  selectedMenu : any;
  level_1: any;
  level_2: any;
  level_3: any;
  level_4: any;
  constructor(
    public toastService: ToastService,
    public activRoute: ActivatedRoute,
    public router: Router,
    public _cd: ChangeDetectorRef,
    public elementRef: ElementRef,
    public data: Data,
    public tokenService: TokenService,
    public utilityService: UtilityService,
    @Inject(PLATFORM_ID) public platformId: Object,
    public languageExtService: LanguageExtService,
    public commonService: CommonService,
    public cookieService: SsrCookieService,
    public courseService:CourseService,
    public accountService: AccountService,
    public gtmService: GtmService
  ) {

    if(GlobalSettings.isBrowser) {
      this.smallLogo = environment.assetsCdnUrl + CdnAssets.logoCdn;
      this.logo = environment.assetsCdnUrl + CdnAssets.blackLogoCdn;
    }
    this.prepareMenuList();
  }

@HostListener('document:keydown.escape', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    of(null).pipe(delay(0),first()).subscribe(()=>{
    const {activeElements,overlayElements} = this.getActiveOrSelectedElements();
    if (overlayElements?.length >= 1 && activeElements?.length > 0) {
      this.handleCascadeMenus();
  }
    })
  }  
  public prepareMenuList() {
    if (!isPlatformBrowser(this.platformId)) {
      this.isShowMenubar = true;
      this.isJS = false;
    } else {
      this.isJS = true;
      this.handleWindowWidth();
    }
    if(!GlobalSettings.isBrowser || !this.isJS){
      this.menuList.forEach(element => element.langKey === "settings" && (element.children = element.children?.filter(child => child.langKey !== "cookiesettings")));
    }
    if (!GlobalSettings.isNative) {
      this.menuList = this.menuList.filter((obj: any) =>
        !obj.isMobile
      )
    }
    if(GlobalSettings.isDesktop && this.data.isOfflineUser) {
      this.menuList = this.menuList.filter((menu) => {
        if(menu.id === 'bookshelf' || menu.id ==='Help_Policies'){
          if (menu.id === 'Help_Policies') {
            menu.url = '/offline-bookviewer/help';
            menu.trailingIcon = '';
            menu.trailingLabel = '';
            menu.hierarchyLevel = '3'
            delete menu.queryParams;
          }
          return menu;
        }
      });
      this.menuList.push({id: 'about',hierarchyLevel: '1', name: 'About', langKey: 'settingsAbout', icon: 'icon-info-circle', trailingIcon: 'icon-chevron-right', isContainSubMenu: true,
        children: [
          { id: 'termsconditions', parentId: '1', hierarchyLevel: '1_0', name: 'Terms and conditions',icon: '',langKey:"helpPoliciesTermCondition",url: "/offline-bookviewer/terms-and-conditions", queryParams: { menuHierarchy: '1' }},
          { id: 'systemrequirements', parentId: '1', hierarchyLevel: '1_1', name: 'System requirements',langKey:"helpPoliciesSystemRequirement",url: "/offline-bookviewer/system-requirements", queryParams: { menuHierarchy: '1' }}
        ]
      });
      this.menuList.push({ id: 'language', hierarchyLevel: '2', name: 'Language', langKey: 'language', icon: 'icon-language', url: "/settings/languageSwitcher"});
      this.menuList.sort((a, b) => parseInt(a.hierarchyLevel) - parseInt(b.hierarchyLevel));
    }
    this.prepareNoJsParamsInCascade(this.menuList);
    this.setActiveAndSelected();
    this.prepareCascadeMenubarLabel();
    this.prepareEventSubscriptions();
  }

  public handleWindowWidth() {
    const windowWidth = window?.innerWidth;
    if (windowWidth > 1024) {
      this.isShowMenubar = true;
    } else {
      this.showTitle = true;
      this.isShowMenubar = false;
    }
  }

  private prepareCascadeMenubarLabel() {
    if(this.themeMenu.themeType === 'Fun' ){
      this.cascadeMenubarLabel = this.KidsthemeLabel;
    } else{
      this.cascadeMenubarLabel =
         !isPlatformBrowser(this.platformId) || this.isMobileWidth()
         ? this.backBtn
         : this.menuBtn;
    }

    if (this.currentUrl.includes('/course-builder/dashboard') || this.currentUrl.includes('/course-builder/cb-myactivities') || this.currentUrl.includes('/course-builder/student-dashboard')) {
      this.cascadeMenubarLabel = this.data.languageJson.global?.global_button.backBtn;
    }
  }

  public prepareEventSubscriptions() {
    this.router.events
      .pipe(takeWhile(() => this.subActive))
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        const queryParams = this.activRoute.snapshot.queryParams;
        if (queryParams['toastId'] === 'error') {
          return;
        }
        this.handleNavigationEnd(event);
        if (!this.data.isCurriculumEreaderUser) {
          this.handleDynamicMenuInNoJS();
        }
        this._cd.detectChanges();
      });
      if (this.data.globalNavigationMenuListStatic == 0) {
          this.data.globalNavigationMenuListStatic = this.menuList;
        } else {
          this.menuList = this.data.globalNavigationMenuListStatic;
      }
    this.subscriptionList.push(
      this.data.globalNavigationMenuList.subscribe((list: any) => {
        this.menuList = list;
        if (GlobalSettings.isBrowser) {
          this.handleNavigationEnd({});
        }
        this._cd.detectChanges();
      })
    );

    this.subscriptionList.push(
      this.languageExtService.languageLoadNotifier.subscribe(
        (status: boolean) => {
          this.handleLanguageLoad(status);
        }
      )
    );
  }
  handleDynamicMenuInNoJS(){
    if (GlobalSettings.isBrowser && this.data.aceToken?.MEEUserId && this.cookieService.get('token_key')) {
      let key: string = this.cookieService.get('token_key')+ `_isCurriculumEreaderUser`
      let reqBody = { "key": key };
      this.courseService.commonGetRedis(reqBody).subscribe((response: any) => {
        this.data.isCurriculumEreaderUser = response;
       if(response === null || response === 'null'){
        this.commonService.getSchoolWorkMenuItems(false);
       }else{
         this.commonService.getSchoolWorkMenuItems(response?.isCurriculumEreaderUser);
       } 
      });
    }
  }
  public handleNavigationEnd(event: any) {
    if (!GlobalSettings.isNative) {
      this.menuList = this.menuList.filter((obj: any) =>
        !obj.isMobile
      )
    }
    this.showOverlay = false;
    this.showTitle = true;
    this.cascadeMenus = [];
    let param = this.activRoute.snapshot.queryParams;
    this.activeMenuHierarchy = param?.activeMenuHierarchy;
    this.currentUrl = this.router.url.split('?')[0];
    if (!this.isJS && this.router.url != '/' && this.router.url != '/logout' && this.router.url != '/download'  && this.currentUrl != '/' && !this.router.url.includes('backClicked') && !this.router.url.includes('themeClass')) {
      this.setRedisUrl(this.router.url);
    }
    if (param?.menuHierarchy) {
      if (param?.hasTheme) {
        this.isCascadeThemeOpens = true;
      } else {
        this.isCascadeThemeOpens = false;
      }
      this.handleMenuHierarchy(param);
    } else {
      this.handleNoMenuHierarchy(param);
    }
    this.setActiveAndSelected();
    this.prepareNoJsParamsInCascade(this.menuList);
    if(this.currentUrl.includes('/course-builder/dashboard') || this.currentUrl.includes('/course-builder/cb-myactivities')){
      this.cascadeMenubarLabel = this.data.languageJson.global?.global_button.backBtn;
    }
    this.updateThemeList();
  }

  updateThemeList () {
    const langJsonObject = this.data.languageJson.menu;
    if (langJsonObject && this.data.themeList) {
      const updatedArrayLangJson = this.data.themeList.map(obj => {
      const newObj = { ...obj };
      newObj.name = langJsonObject[obj.class];
        return newObj;
      });
      this.data.themeList = updatedArrayLangJson;
    }
    this.hasThemeOption = this.data?.menuToggleEnvObject?.theme ? true: false;
    this.setActiveTheme();
  }

  setRedisUrl(URLhistry:any) {
    if(!this.data.aceToken?.MEEUserId || this.theamUrl == URLhistry || URLhistry.includes('?toastId=')){
      return false;
    }
    let data = { 'routeUrlHistry': URLhistry };
    let reqBody = { "key": `routeUrlHistry_${this.data.aceToken?.MEEUserId +"_"+this.data.aceToken?.MachineIdentifier}`, "value": JSON.stringify(data), "cacheValidity": 1000000 };
    this.theamUrl = URLhistry;
    this.courseService.commonSetRedis(reqBody).pipe(takeUntil(this.destroy$)).subscribe((res)=>{ });
  }

  public handleMenuHierarchy(param: any) {
    this.getMenuItemAndShowNavigation(param.menuHierarchy.split('_'));
    if (!GlobalSettings.isNative) {
      if (!isPlatformBrowser(this.platformId)) {
        this.isShowCascadebar = true;
        this.isShowMenubar = false;
        this.cascadeOpened.emit(true);
      }
    }
    if (this.isMobileWidth()) {
      this.isShowCascadebar = false;
    }
  }

  public handleNoMenuHierarchy(param: any) {
    if (this.isMobileWidth()) {
      this.isShowMenubar = false;
    } else {
      this.isShowMenubar = true;
    }
    if (!GlobalSettings.isNative) {
      if (!isPlatformBrowser(this.platformId) && param?.activeMenuHierarchy) {
        this.showOverlay = true;
      }
    }
  }

  public handleLanguageLoad(status: boolean) {
    if (this.data.languageJson) {
      this.backBtn = this.data.languageJson.global?.global_button.backBtn;
      this.menuBtn = this.data.languageJson.global?.global_button.menuBtn;
      this.KidsthemeLabel = this.data.languageJson.global?.global_button.thememenuBtn;
      this.themeSelectionTitleLabel = this.data.languageJson.global?.global_button.theme_selection_title_label;
    }
    this.prepareCascadeMenubarLabel();
    this.data.globalNavigationMenuListStatic = this.changeLanguageText(
      this.menuList
    );
    this._cd.detectChanges();
  }

  ngOnInit(): void {
    this.globalNavSmallDevice();
    this.subscriptionList.push(
      this.data.isShowGlobalMenu.subscribe((value: any) => {
        if (value === 'login') {
          this.isShowGlobalmenu = false;
        } else {
          this.isShowGlobalmenu = true;
        }
      })
    );

    this.subscriptionList.push(this.data.isDataThemeSet.subscribe((res)=>{
      if (res) {
        this.updateThemeList();
        this.data.isDataThemeSet.next(false);
        this._cd.detectChanges();
      }
    }));
    
    if (!this.isJS) {
      this.activRoute.queryParams.pipe(takeUntil(this.destroySubscription)).subscribe(param => {
        if(param && param.themeClass) {
          const theme = this.data.themeList.find((theme:any) => theme.class === param.themeClass);
          if (theme) {
            this.themeMenu = theme;
            this.data.userSelectedThemeClass = theme.class;
            this.data.userSelectedThemeId = theme.id;
            this.data.userSelectedThemeObj = theme;
          }
          this.data.isUserSelectedThemeClass.next(this.data.userSelectedThemeClass);
        }
      });
    }   
}

  anchorTabFocus() {
    of('HTML focus').pipe(delay(300)).subscribe(() => {
      const activeTabLinks = document.getElementsByClassName("c-menu__list-item-link active");      
      const schoolwork = document.getElementsByClassName("c-menu__list-item-link--is-selected");
      if (activeTabLinks.length === 1 && schoolwork.length === 0) {
        const activeTabLink = activeTabLinks[0] as HTMLElement;
        // Check if the element is not already focused
        if (this.focusableElement !== activeTabLink) {
          this.focusableElement = activeTabLink;
          this.focusableElement.focus();
        }
      } else {
        // Dynamically get the element based on currentIndex
        this.focusableElement = document.getElementById(`CascadeMenubar${this.cascadeMenus.length-1}menu`);
  
        if (this.focusableElement) {
          this.focusableElement.focus();
        }
      }
    });
  }
  

  globalNavSmallDevice() {
    this.subscriptionList.push(
      this.data.loadGlobalNavidation.subscribe((value: any) => {
        this.GlobalNavidationValue = value;
        if (value === 'hamburger') {
          let menuHr = this.activRoute.snapshot.queryParamMap?.get('menuHierarchy')?.split('_');
          this.getMenuItemAndShowNavigation(menuHr);
          this.setActiveAndSelected();
          this._cd.detectChanges();
        } else if (value === 'closeHamburger') {
          if (this.commonService.getDeviceType() == getdeviceType.mobile) {
            this.isShowMenubar = false;
            this.isShowCascadebar = false;
          }
          if (this.commonService.getDeviceType() == getdeviceType.tablet) {
            this.isShowMenubar = true;
            this.isShowCascadebar = true;
            this._cd.detectChanges();
          }
        }
      })
    );
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if ((event.ctrlKey && event.key === '0') || (event.ctrlKey && event.key === '-')) {
      if (this.currentUrl.includes('/courselist') || this.currentUrl.includes('/course-details') || this.currentUrl.includes('/notifications')) {
        this.isShowMenubar = true;
        this.isShowCascadebar = false;
      } else {
        this.isShowMenubar = false;
        this.isShowCascadebar = true;
      }
    }
    if (this.GlobalNavidationValue === 'hamburger') {
      if (event.key === 'Control') {
        this.ctrlKeyPressed = true;
      } else if (this.ctrlKeyPressed && event.key === '=') {
        this.equalKeyPressed = true;
      } else if (this.ctrlKeyPressed && event.key === '-') {
        this.minusKeyPressed = true;
      }
    }
  }

  @HostListener('window:keyup', ['$event'])
  onKeyUp(event: KeyboardEvent) {
    if (event.key === 'Control') {
      this.ctrlKeyPressed = false;
    } else if (event.key === '=') {
      if (this.equalKeyPressed) {
        if (this.currentUrl.includes('/courselist') || this.currentUrl.includes('/course-details')) {
          if (this.GlobalNavidationValue === 'hamburger') {
            this.isShowMenubar = true;
            this._cd.detectChanges();
          }
        }
      }
      this.equalKeyPressed = false;
    } else if ( event.key === '-') {
      if (this.minusKeyPressed) {
        if (this.currentUrl.includes('/courselist') || this.currentUrl.includes('/course-details')) {
          if (this.GlobalNavidationValue === 'hamburger') {
            this.isShowMenubar = true;
            this._cd.detectChanges();
          }
        }
      }
    }
    this.minusKeyPressed = false;
  }
  
  ngAfterViewInit(): void {
    this.elementRef.nativeElement.addEventListener(
      'keydown',
      ($event: any) => {
        this.handleKeyDown($event);
      },
      true
    );
  }
  handleKeyDown(event: KeyboardEvent) {
    if (this.elementRef && this.elementRef.nativeElement && this.showOverlay) {
      this.focusableElements = this.elementRef.nativeElement.querySelectorAll(
        'app-global-navigation button, app-global-navigation [href]'
      );
      if (this.focusableElements.length > 0) {
        const lastElement =
          this.focusableElements[this.focusableElements.length - 1];
        const currentElement = event.target;
        if (lastElement === currentElement) {
          if (
            event.shiftKey &&
            event.key === 'Tab' &&
            this.focusableElements[this.focusableElements.length - 2]
          ) {
            this.focusableElements[this.focusableElements.length - 2].focus();
            event.preventDefault();
          } else if (event.key === 'Tab' && this.focusableElements[0]) {
            this.focusableElements[0].focus();
            event.preventDefault();
          }
        }
      }
    }
  }

  getMenuItemAndShowNavigation(hierarchyLevel: any) {
    this.cascadeMenus = [];
    this.showTitle = true;
    if (hierarchyLevel?.length > 0 && hierarchyLevel[0] > -1) {
      let item = this.getMenuItem(this.menuList, hierarchyLevel);
      if (item) {
        this.cascadeMenus.push({
          name:
            !isPlatformBrowser(this.platformId) || this.isMobileWidth()
              ? this.backBtn
              : this.menuBtn,
          isVisible: true,
          hierarchyLevel: hierarchyLevel,
          menus: item,
        });
        this.showOverlay = !isPlatformBrowser(this.platformId) ? true : false;
        this.isShowCascadebar = true;
        this.isShowMenubar = false;
        this.cascadeOpened.emit(true);
      }
    } else {
      this.showOverlay = false;
      this.isShowCascadebar = false;
      this.isShowMenubar = true;
      this.cascadeOpened.emit(false);
    }
    this._cd.detectChanges();
  }

  openMenu_gnc () {
    if (this.isShowMenubar && this.isShowCascadebar) {
      this.data.loadGlobalNavidation.emit("hamburger");
    } else if (!this.isShowMenubar && this.isShowCascadebar) {
      if (this.currentUrl.includes('/school-work') || this.currentUrl.includes('/homework-assessment') || this.currentUrl.includes('/course-builder') || this.currentUrl.includes('/extra-practices')) {
        this.data.loadGlobalNavidation.emit("closeHamburger")
        this.showHamburger = false;
      } else {
        this.data.loadGlobalNavidation.emit("hamburger");
        this.showHamburger = true;
      }
    } else {
      this.data.loadGlobalNavidation.emit("closeHamburger");
      this.showHamburger = false;
    }
    this._cd.detectChanges();
  }

  @HostListener('window:resize', ['$event'])
  @HostListener('window:orientationchange',['$event'])
  orientationchange(event : Event) {
    requestAnimationFrame(()=>{
    if (isPlatformBrowser(this.platformId)) {
      let menuHr = this.activRoute.snapshot.queryParams.menuHierarchy?.split('_');
      let deviceOrientation: string = (window?.innerWidth > window?.innerHeight) ? 'landscape':'portrait';
      if(menuHr?.length > 0) {
          this.showTitle = true;
          if(this.isShowCascadebar && this.cascadeMenus.length===0 && !this.isShowMenubar){
            let selectedCascadeList = this.menuList.find((res:any)=>res.selected === true);            
            this.cascadeMenus.push({
              name: '',
              isVisible: true,
              hierarchyLevel: selectedCascadeList.hierarchyLevel,
              menus: selectedCascadeList.children,
            });
          }
        this.openMenu_gnc ();
      }
      if(deviceOrientation === 'portrait') {
        this.showTitle = true;
        if (menuHr == undefined) {
          setTimeout(() => {
            let menuButton:any = document.querySelector('#hamburgerIcon[aria-label="hamburger button"]');
            if (menuButton) {
              menuButton.click();
            } 
            this._cd.detectChanges();
          }, 1000);
        } else {
          this.isShowMenubar = false;
          this.isShowCascadebar = this.showHamburger || window.innerWidth >= 1023 ? true:false;
          this.cascadeOpened.emit(false);
          this._cd.detectChanges();
        }
      } else if (deviceOrientation === 'landscape') {
        if (menuHr == undefined) {
          this.isShowMenubar = true;
          this.isShowCascadebar = false;
        } else {
          if (GlobalSettings.isBrowser && !GlobalSettings.isNative && this.isMobileWidth()) {
          this.isShowMenubar = false;
          this.isShowCascadebar = this.showHamburger? true:false;
        } else {
          this.isShowMenubar = false;
          this.isShowCascadebar = true;
          this.cascadeOpened.emit(true);
          }
        }
      } else {
        this.isShowMenubar = true;
        this.showTitle = false;
        this.cascadeOpened.emit(true);
      }
        this._cd.detectChanges();     
      }
      const hTMLElement = document.querySelector('body');
      if(!this.isMobileWidth() || (this.isMobileWidth() && (window?.innerWidth > window?.innerHeight))) {
        if (hTMLElement && hTMLElement.classList?.contains('nav-cascade-without-header') && this.isCascadeThemeOpens) {
          hTMLElement.classList.remove('nav-cascade-without-header');
        }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.showMenu) {
      let param =
        this.activRoute.snapshot.queryParams.menuHierarchy?.split('_');
      if (changes.showMenu.currentValue && this.isMobileWidth()) {
        if (this.cascadeMenus.length > 0 && param) {
          this.isShowCascadebar = changes.showMenu.currentValue;
        } else if (!param || this.isMobileWidth()) {
          this.isShowMenubar = changes.showMenu.currentValue;
        }
      } else if (
        changes.showMenu.currentValue === false &&
        this.isMobileWidth()
      ) {
        this.isShowMenubar = false;
        this.isShowCascadebar = false;
        this.menuList.forEach((obj: any, menuInx: number) => {
          if (obj.selected === true && !param) {
            obj.selected = false;
          }
        });
      }
    }
  }
  changeLanguageText(listItem: any) {
    if (this.data.languageJson.menu?.[listItem.langKey]) {
      listItem.name = this.data.languageJson.menu?.[listItem.langKey];
    }
  if (listItem.children?.length > 0) {
      listItem.children.forEach((child: any) => {
          return this.changeLanguageText(child);
      });
  } else if (Array.isArray(listItem)) {
      listItem.forEach((item: any) => {
          return this.changeLanguageText(item);
      });
  }
    return listItem;
  }
  ngDoCheck(){
    if(this.isCascadeThemeOpens){
      this.optInStatusIcon = 'icon-chevron-right';
      this.isThemeMenubarExpand = true;
    }else{
      this.optInStatusIcon = 'icon-chevron-right';
      this.isThemeMenubarExpand = false;
    }
  }
  prepareNoJsParamsInCascade(listItems: any) {
    if (listItems?.length > 0) {
      listItems.forEach((val: any, index: number) => {
        let param = this.activRoute.snapshot.queryParams;
        if((val.hasOwnProperty("hasTheme")) && this.data.languageJson?.menu) {
          val.name =  this.data.languageJson.menu[val.class];
        } 
        if((val.hasOwnProperty("isTitle")) && this.data.languageJson?.menu) {
          val.name =  this.data.languageJson.menu[val.id];
        }
        if (param.themeClass && (param.themeClass === val.class) && !val.isTitle && !this.isJS) {
          this.themeMenu = val;
          this.data.isUserSelectedThemeClass.next(val.class);
        }
        this.appendUrlCheckSelected(val, index);
        if (val.children?.length > 0) {
          this.prepareNoJsParamsInCascade(val.children);
        }
      });
    }
  }
  appendUrlCheckSelected(val: any, index: number) {
    if (val.url !== '/logout' && val.id !== 'personaldetails') {
      if (!isPlatformBrowser(this.platformId) && val.isContainSubMenu) {
        val.url = './' + this.router.routerState.snapshot.url.split('?')[0];
        val.queryParams = {
          menuHierarchy: val.hierarchyLevel,
          activeMenuHierarchy: this.activeMenuHierarchy, 
          hasTheme: val.hasTheme ? val.hasTheme : null,
          themeClass: this.data.userSelectedThemeClass,
          themeSelected:null 
        };
      } else {
        val.queryParams = {
          ...val.queryParams,
          ver: Math.random().toString(36).substring(2, 5),
          activeMenuHierarchy: this.activeMenuHierarchy,
        };
        if(val.hasTheme) {
          val.queryParams = {
            ...val.queryParams,
            themeId: val.id,
            themeClass: val.class,
            hasTheme: val.hasTheme ? val.hasTheme : null,
            themeSelected:true,
          }
        }
      }
    }
  }
  getMenuItem(navItems: any, hierarchyLevel: any) {
    let item: any = {};
    let singleMenu = hierarchyLevel.join('_');
    if (hierarchyLevel[0] && navItems[hierarchyLevel[0]]?.children) {
      let childItems = navItems[hierarchyLevel[0]]?.children;
      hierarchyLevel.splice(0, 1);
      item = this.getMenuItem(childItems, hierarchyLevel);
    } else if ((hierarchyLevel[0]-hierarchyLevel.length ) && navItems[(hierarchyLevel[0]-hierarchyLevel.length)]?.children) {
      let childItems = navItems[hierarchyLevel[0]-hierarchyLevel.length].children;
      hierarchyLevel.splice(0, 1);
      item = this.getMenuItem(childItems, hierarchyLevel);
    } else if (singleMenu && navItems[0]?.hierarchyLevel) {
      let childItems = navItems[0]?.children;
      hierarchyLevel.splice(0, 1);
      item = this.getMenuItem(childItems, hierarchyLevel);
    } else {
      item = navItems;
    }
    return item;
    
  }
  isMobileWidth() {
    if (isPlatformBrowser(this.platformId)) {
      return window?.innerWidth < 1023;
    } else {
      return false;
    }
  }
  setActiveAndSelected(str?: string) {
    let param = this.activRoute.snapshot.queryParams;
    let urlHierarchyLevel =
    this.activRoute.snapshot.queryParams.menuHierarchy?.split('_');
    let urlChild = this.router.routerState.snapshot.url.split('?')[0];
    this.menuList.forEach((obj: any) => {
      if (obj.url && obj.url === urlChild && !obj.url.includes('courselist/help')) {
        obj.active = true;
        this.activeMenuHierarchy = obj.queryParams?.menuHierarchy;
        if (!GlobalSettings.isNative) { 
          if (!isPlatformBrowser(this.platformId)) {
            this.showOverlay = false;
          }
        }
      } else if (obj.url && obj.url === urlChild && obj.url.includes('courselist/help')) {
        this.menuList[0].active = true;
      }
      else {
        obj.active = false;
      }
      if (!isPlatformBrowser(this.platformId) && !GlobalSettings.isNative) {
        let params = this.activRoute.snapshot.queryParams;
        if (params.lastSelected && params.activeMenuHierarchy && obj.hierarchyLevel === params.activeMenuHierarchy) {
          obj.selected = true;
        } else {
          obj.selected = false;
        }
      } else if (
        urlHierarchyLevel &&
        obj.hierarchyLevel === urlHierarchyLevel[0] &&
        (obj.hierarchyLevel === this.cascadeMenus[0]?.hierarchyLevel || this.cascadeMenus.length === 0)
      ) {
        obj.selected = true;
      } else if (
        (this.cascadeMenus[0]?.hierarchyLevel &&
          obj.hierarchyLevel !== this.cascadeMenus[0].hierarchyLevel)
      ) {
        obj.selected = false;
              } else if (this.cascadeMenus[0]?.hierarchyLevel &&
        obj.hierarchyLevel === this.cascadeMenus[0].hierarchyLevel) {
        obj.selected = true;
      }
      else {
        obj.selected = false;
              }
    });
    if (this.cascadeMenus.length > 0) {
      let urlHierarchyLevel = this.activRoute.snapshot.queryParams.menuHierarchy;
      this.cascadeMenus?.forEach((menu: any, cascadeInx: number) => {
        menu.menus?.forEach((obj: any, menuInx: number) => {
          if(!this.isJS){
            if((obj.class === param.themeClass)){
              obj.selected = true;
            }
            else{
              obj.selected = false;
                          }
          }
          if (obj.url && obj.url === urlChild) {
            obj.active = true;
            this.activeMenuHierarchy = obj.queryParams.menuHierarchy;
            if (!GlobalSettings.isNative) {
              if (!isPlatformBrowser(this.platformId)) {
                this.showOverlay = false;
              }
            }
          } else if(obj.class == this.themeMenu.class){
            obj.selected = true;
          } else if(obj.id == 'schoolProgress' && obj.activeurl && obj.activeurl === urlChild) {
            obj.active = true;
          }
          else {
            obj.active = false;
            obj.selected = false;
                      }
          if (!isPlatformBrowser(this.platformId) && !GlobalSettings.isNative) {
            let lastSelected =
              this.activRoute.snapshot.queryParams.lastSelected;
            if (
              lastSelected &&
              cascadeInx === 0 &&
              menuInx === parseInt(lastSelected)
            ) {
              obj.selected = true;
            } else if (!obj.hasTheme) {
              obj.selected = false;
                          }
          } else if (
            str === 'backClick' &&
            urlHierarchyLevel &&
            urlHierarchyLevel === obj.hierarchyLevel
          ) {
            obj.selected = true;
          }else if(obj.class == this.themeMenu.class){
            obj.selected = true;
          } 
           else if (
            !this.cascadeMenus[cascadeInx + 1] &&
            !(urlHierarchyLevel && urlHierarchyLevel[cascadeInx + 1] && !this.isCascadeThemeOpens)
          ) {
             obj.selected = false;
            }
        });
      });
    }
  }
  onCloseMenubar() {
    this.isShowMenubar = false;
    this.menuList.forEach((obj: any) => (obj.active = false));
  }

  getMenuFromLangJson(menu: any) {
    if (menu.id === "theme") {
      const langJsonObject = this.data.languageJson.menu;
      if (langJsonObject && menu.children) {
        const updatedArrayLangJson = menu.children.map((obj:any) => {
          const newObj = { ...obj };
          if (newObj.isTitle) {
            newObj.name = langJsonObject[obj.id];
            return newObj;
          } else {
            newObj.name = langJsonObject[obj.class];
            return newObj;
          }
        }); 
        menu.children = updatedArrayLangJson;
      }
    }
    this._cd.detectChanges();
  }
  
  onClickMenu(menu: any) {
    if(menu.id === 'bookshelf'){
      let courseNames: string = this.data?.courses?.map(course => course.courseName + ' ' + course.courseLevel).join(', ');
      this.gtmService.advanceTracking(VIEW_COURSE_CONTENT, GTMEvents.ViewCourseList, {course_name: courseNames})
    }
    this.isOnline = window.navigator.onLine;
    this.checkInternetStatus(menu);
    this._cd.detectChanges();
  }

  setCascadeMenuOnMenuClick(menu) {
    this.getMenuFromLangJson(menu)
      if(menu.id === "adminCalss"){
        this.data.isLoadingLgoin.emit(true)
        this.classAdminRedirect();
        return;
      }
      this.cascadeMenus = [];
      this.showTitle = false;
      if (isPlatformBrowser(this.platformId)) {
        this.isShowCascadebar = true;
      }
      if (menu.isContainSubMenu) {
        this.showOverlay = true;
        this.cascadeMenus.push({
          name: menu.name,
          isVisible: true,
          hierarchyLevel: menu.hierarchyLevel,
          menus: menu.children,
        });
        if (menu.hasTheme) {
          this.isCascadeThemeOpens = true;
          this.cascadeMenubarLabel = (this.isMobileWidth())? this.themeSelectionTitleLabel:this.KidsthemeLabel;
        } else {
          this.isCascadeThemeOpens = false;
          this.cascadeMenubarLabel = (!isPlatformBrowser(this.platformId) || this.isMobileWidth()) ? this.backBtn : this.menuBtn;
        }
        this.isShowMenubar = !this.isMobileWidth();
        this.setActiveAndSelected();
        menu.selected = true;
      }
      else{
        this.isCascadeThemeOpens = false;
        this.getSelectedMenu(menu);
      }
      this.cascadeThemeOpened.emit(this.isCascadeThemeOpens);
      this.anchorTabFocus();
      this._cd.detectChanges();
  }


  checkInternetStatus(menu) {
    if (GlobalSettings?.isBrowser) {
      if (this.isOnline) {
        this.isInternetAvailable = true;
        this.setCascadeMenuOnMenuClick(menu);
      } else {
        if (menu.hasTheme){
          this.isInternetAvailable = false;
        } else {
          this.setCascadeMenuOnMenuClick(menu);
        }
      }
    } else {
      this.utilityService.checkInternetConnectivity().pipe(first()).subscribe((isInternetAvailable) => {
        if (isInternetAvailable) {
          this.isInternetAvailable = true;
          this.setCascadeMenuOnMenuClick(menu);
        } else {
          if (menu.hasTheme) {
            this.isInternetAvailable = false;
          } else {
            this.setCascadeMenuOnMenuClick(menu);
          }
        }
      });
    }
  }

  classAdminRedirect() {
    this.data.isLoadingLgoin.emit(true);
    let redirectUrl:string = '';
    let currentUrl = window.location.href;
    let path = encodeURIComponent('courses/courselist');
    let platformUrl = '';
    if (GlobalSettings.isBrowser) {
      redirectUrl = `environment=${environment.name.toLocaleUpperCase()}&language=${this.data.selectedLanguage}`;
      //redirectUrl = `environment=localhost&port=4200&language=${this.data.selectedLanguage}`;//Dont Remove this line its use for local 
    } else if (GlobalSettings.isDesktop) {
      let rootUrl = currentUrl.split('/').slice(0, 3).join('/');
      redirectUrl = `environment=localhost&port=`+new URL(rootUrl).port+`&language=${this.data.selectedLanguage}`
    } else if (GlobalSettings.isNative && GlobalSettings.routePath.includes('android')) {
      redirectUrl = `environment=android&port=8080&language=${this.data.selectedLanguage}`;
    } else if (GlobalSettings.isNative && !GlobalSettings.routePath.includes('android')) {
      redirectUrl = `environment=ios&port=9080&language=${this.data.selectedLanguage}`;
    }

    if (GlobalSettings.isBrowser) {
      this.redirectToClassAdmin(redirectUrl);
    } else {
      this.utilityService.checkInternetConnectivity().subscribe((internetStatus) => {
        if (internetStatus) {
          this.accountService.setRedis(this.data.aceToken).pipe(first()).subscribe(() => {
            this?.redirectToClassAdmin(redirectUrl);
          });
        } else {
          this.data.isLoadingLgoin.next(false);
          this.toastService.showMessage(ToastMessageType.ERROR, this.data.languageJson?.dashboard?.homework?.showOfflineMessage?.noInternetLabel);
        }
      });
    }
  }
  redirectToClassAdmin(redirectUrl: string) {
    let newUrl = '/courses/courselist';
    const state = { data: 'newurl' };
    if(!GlobalSettings.isBrowser) {
        newUrl = GlobalSettings.routePath+'/index.html';
    }
    this.accountService.classAdmin().subscribe(res => {
      location.href = `${res}?${redirectUrl}`;
    });
  }
  onClickMoreMenu() {
    this.onClickCascadeClose();
  }
  setActiveTheme(){
    if(this.data.themeList && this.data.userSelectedThemeId){
      let activeTheme = this.data.themeList.find((obj:any)=>obj.id === this.data.userSelectedThemeId);
      if (activeTheme) {
       this.themeMenu = {...activeTheme};
      }
      this._cd.detectChanges();
    }
  }
  
  onClickCascadeMenu(menu: any, index: number) {
    if(menu.id === 'personaldetails'){
      this.gtmService.advanceTracking(Settings, GTMEvents.ButtonClick, {button_name: menu.name, click_text: this.data?.languageJson?.menu?.settingsPersonalDetail})
    }
    if (GlobalSettings?.isNative && menu.hasTheme) {
      this.data.isLoadingLgoin.emit(true);
    }
    if(!this.data.isOfflineUser){
      if (!this.data?.meeToken) {
        this.tokenService.getMeeToken().pipe(first()).subscribe(res => {
          if (res) {
            this.tokenService.setMEEToken(res);
            this.setThemeByUser(menu);
          }
        });
      } else {
        this.setThemeByUser(menu);
      }
    }
    this.cascadeMenus.splice(index + 1, this.cascadeMenus.length - (index + 1));
    if (menu.isContainSubMenu) {
      this.showOverlay = true;
      this.cascadeMenus.push({
        name: menu.name,
        isVisible: true,
        menus: menu.children,
      });
       // Focus the first element of the new sub-menu using a unique ID based on the index
       of(null).pipe(
        delay(0), 
        first()
      ).subscribe(() => {
        const firstSubMenuElement = document.getElementById(`CascadeMenubar${index + 1}menu`);
        if (firstSubMenuElement) {
          firstSubMenuElement.focus();
        }
      });
   
    } else {
      if (this.isCascadeThemeOpens) {
        this.themeMenu = menu;
        let themeElemet = document.getElementById('themeMenuId');
        if(themeElemet) {
          themeElemet?.focus();
        }
        if(window.innerWidth < this.WindowMaxWidth){
          of('Observable To Update HTML').pipe(delay(100)).subscribe(() => {
          let haburgerButton = document.getElementById('hamburgerIcon');
          haburgerButton?.focus();
          });
        }
        this.isCascadeThemeOpens = false;
        this.cascadeThemeOpened.emit(false);
      }
      this.cascadeOpened.emit(false);
    }
    this.setActiveAndSelected();
    menu.selected = true;
    if(menu.selected && !menu.isContainSubMenu){
     this.getSelectedMenu(menu);
    }
    if(menu.name === dashboardTypes.assessment){
      this.gtmService.advanceTracking(dashboardTypes.assessment,'assessment_accessed_from_global_nav',
      {
        date: new Date(),
      });
    }
  }

  setThemeByUser(menu:any) {
    if (menu && menu.themeCategory && menu.hasTheme) {
      this.subscriptionList.push(this.commonService.themeChangedByUser(menu.id).subscribe((response:any)=>{
        if (response) {
          this.data.userSelectedThemeId = menu.id;
          this.setActiveTheme();
          this.commonService.getNameById();
          this.ovarlayClickJS();
          if (this.isMobileWidth()) {
            let buttonClick = document.getElementById('hamburgerIcon');
            let buttonClickLang = document.getElementById('hamburgerIconlang');
            if (buttonClick) {
              buttonClick.click();
              this.cascadeMenubarLabel = (menu.isDefault)? this.backBtn : this.KidsthemeLabel;
            } else if (buttonClickLang) {
              buttonClickLang.click();
              this.cascadeMenubarLabel = (menu.isDefault)? this.backBtn : this.KidsthemeLabel;
            }
          } else {
            this.cascadeMenubarLabel = (menu.isDefault)? this.menuBtn : this.KidsthemeLabel;
          }
        }
        if (GlobalSettings?.isNative) {
          this.data.isLoadingLgoin.emit(false);
        }
        this._cd.detectChanges();
      }));
      this._cd.detectChanges();
    }
  }
  onClickCascadeBack(index: number) {
    if (isPlatformBrowser(this.platformId)) {
      this.showTitle = false;
      let menuHr = this.activRoute.snapshot.queryParams.menuHierarchy?.split('_');
      if (this.isMobileWidth()) {
        let mobileMenuHr = this.activRoute.snapshot.queryParams.menuHierarchy;
        let removedCascadeMenu = this.cascadeMenus.splice(index, 1);
        if (this.cascadeMenus.length > 0) {
          this.cascadeMenus[this.cascadeMenus.length - 1].isVisible = true;
        }
        if (this.isCascadeThemeOpens) {
          this.isCascadeThemeOpens = false;
          this.cascadeThemeOpened.emit(false);
        }
        this.setActiveAndSelected('backClick');
        if (this.cascadeMenus.length === 0 && menuHr && menuHr[0] === removedCascadeMenu[0].menus[0].parentId) {
          let desiredItem = this.menuList.find((obj: any) => obj.hierarchyLevel === removedCascadeMenu[0].menus[0].parentId + '');
          desiredItem.selected = true;
        } else if (this.cascadeMenus.length === 0 && mobileMenuHr === removedCascadeMenu[0].menus[0].parentId) {
          let desiredSubMenu = this.menuList.filter((res:any)=>res.id === "School");
          let menu = desiredSubMenu[0].children.find((res:any)=>res.hierarchyLevel === removedCascadeMenu[0].menus[0].parentId);
          this.cascadeMenus.push({
            name: '',
            isVisible: true,
            hierarchyLevel: desiredSubMenu[0].hierarchyLevel,
            menus: desiredSubMenu[0].children,
          });
          menu.selected = true;
        }
      } else {
        this.cascadeMenus = [];
        menuHr?.forEach((hierachyInx: any, inx: number) => {
          let desiredItem:any = this.menuList.find((obj: any) => obj.hierarchyLevel === hierachyInx + '');
          if (inx === 0) {
            this.cascadeMenus.push({
              name: '',
              isVisible: true,
              hierarchyLevel: desiredItem.hierarchyLevel,
              menus: desiredItem.children,
            });
          } else if (inx === 1 && (this.cascadeMenus[0]?.menus[0]?.isContainSubMenu || this.cascadeMenus[0]?.menus[1]?.isContainSubMenu)) {
            let subCascadeMenus = this.cascadeMenus[0].menus.filter((menu:any)=>menu.hierarchyLevel === this.activRoute.snapshot.queryParams.menuHierarchy);
            this.cascadeMenus.push({
              name: '',
              isVisible: true,
              hierarchyLevel: subCascadeMenus[0]?.hierarchyLevel,
              menus: subCascadeMenus[0]?.children,
            });
          } else {
            return;
          }
        });
        this.showOverlay = true;
        this.isShowMenubar = true;
        this.isShowCascadebar = true;
        let backFocusElement:any = document.querySelector(".c-menu__list-item-link.active");
        if (backFocusElement) {
            backFocusElement.focus();
        }
        this.cascadeOpened.emit(false);
        this.setActiveAndSelected('backClick');
      }
      if (this.cascadeMenus.length === 0) {
        this.onClickCascadeClose();
      }
    }
    this._cd.detectChanges();
  }
  onClickCascadeClose() {
    this.isShowCascadebar = false;
    this.isShowMenubar = true;
  }
  hasTitleBar(index: number) {
    if (isPlatformBrowser(this.platformId)) {
      if (this.isMobileWidth() || (this.showTitle && index === 0)) {
        return true;
      } else {
        return false;
      }
    } else if (this.showTitle && index === 0) {
      return true;
    } else {
      return false;
    }
  }

  getBackQueryParam(cascadeMenu: any) {
    let menuArr = cascadeMenu?.menus?.[0]?.parentId?.split('_');
    const lastSelected = menuArr?.splice(-1, 1);
    const contentId = this.activRoute.snapshot.queryParamMap?.get('contentID');
    return {
      menuHierarchy: (menuArr?.length > 0)? menuArr?.join('_'): null,
      lastSelected: lastSelected,
      activeMenuHierarchy: this.isJS? this.activeMenuHierarchy : lastSelected,
      contentID : contentId? contentId: '',
      backClicked:true,
      themeClass:this.data.userSelectedThemeClass
    };
  }

  getBackUrl() {
    let backurl = './' + this.router.routerState.snapshot.url.split('?')[0];
    if (isPlatformBrowser(this.platformId)) {
      backurl = '';
      if (!(this.data?.aceToken?.Role?.toLowerCase() == 'student') && (this.currentUrl.includes('/course-builder/dashboard') || this.currentUrl.includes('/course-builder/cb-myactivities'))) {
        backurl = `/courses/course-details/${this.activRoute.snapshot.queryParams?.subscriptionId}`;
      }
    } else if (!isPlatformBrowser(this.platformId) && backurl.includes('builder/myquestion')) {
      backurl = '/homework-assessment/dashboard/assessment'
    }
    return backurl;
  }

  ovarlayClickJS() {
    this.isCascadeThemeOpens = false;
    if (this.isShowMenubar) {
      let menuHr = this.activRoute.snapshot.queryParamMap
        .get('menuHierarchy')
        ?.split('_');
      this.getMenuItemAndShowNavigation(menuHr);
      this.setActiveAndSelected();
    } else {
      this.cascadeMenus.splice(1, this.cascadeMenus.length - 1);
    }
    this.showOverlay = false;
  }

  trackByFn(index: number): number {
    return index ; // Unique identifier for each cascadeMenu
  }

  onCancel(event: any) {
    this.isInternetAvailable = !event;
    of('update focus').pipe(first(), delay(300)).subscribe(() => {
      const themeElement = document.getElementById('themeMenuId');
      themeElement?.focus();
    });
  }

  onTryAgain() {
    this.data.isLoadingLgoin.emit(true);
    if (!GlobalSettings?.isBrowser) {
      this.subscription = this.utilityService.checkInternetConnectivity().pipe(first()).subscribe((isInternetAvailable) => {
        this.isInternetAvailable = isInternetAvailable;
      });
    } else {
      this.isInternetAvailable = window.navigator.onLine ? true : false;
    }
    this.exitInternetLoader();
  }

  exitInternetLoader() {
    of('update focus').pipe(first(), delay(500)).subscribe(() => {
      this.data.isLoadingLgoin.emit(false);
    });
  }

// Function to handle cascade menus and their related logic
handleCascadeMenus(): void {
  if (this.isCascadeThemeOpens) {
    this.focusOnThemeMenu();
  } else if (this.cascadeMenus.length) {
    this.handleCascadeFocus();
  } else {
    this.handleMenuFocusFallback();
  }
  this.cleanupMenuDisplay();
}

//  Function to get active or selected overlay menu elements
getActiveOrSelectedElements(): any {
  const activeElements = document.getElementsByClassName('c-menu__list-item-link active');
  const overlayElements = document.getElementsByClassName('c-navigation__overlay');
  if (activeElements && activeElements?.length==0) {
    const selectedElements = document.getElementsByClassName('c-menu__list-item-link--is-selected');
    return { activeElements: selectedElements, overlayElements };
  }
  return { activeElements,overlayElements };
}
  
// Function to focus on the theme menu and reset cascade states
focusOnThemeMenu(): void {
  const themeElement = document.getElementById('themeMenuId');
  themeElement?.focus();
  this.isCascadeThemeOpens = false;
  this.showOverlay = false;
  this.cascadeMenus.splice(this.cascadeMenus.length - 1, 1);
}

// Function to handle focusing when cascade menus exist
handleCascadeFocus(): void {
  const index = this.cascadeMenus.length - 1;
  const cascadeMenuItem = this.getCascadeMenuItem(index);
  const focusedElement:any = this.getFocusedElementFromMenu(cascadeMenuItem);
  focusedElement?.focus();
  if (this.isShowMenubar) {
    this.cascadeMenus.splice(index + 1, 1);  // Remove after focusing
  } else {
    this.handleOverlayAndCascadeRemoval(index);
  }
}

// Function to get the appropriate cascade menu item
getCascadeMenuItem(index: number): HTMLElement | null {
  let cascadeMenuItem:any;
  if (index > 0) {
    cascadeMenuItem = document.getElementById(`CascadeMenubar${index - 1}menu`);
  } else {
    cascadeMenuItem = document.getElementById('Menubar');
  }
  return cascadeMenuItem;
}

// Function to get the focused element from a menu (active or selected)
getFocusedElementFromMenu(menuItem: HTMLElement | null): any {
  if (menuItem) {
    let focusedElement = menuItem.querySelector('.c-menu__list-item-link.active');
    if (!focusedElement) {
      focusedElement = menuItem.querySelector('.c-menu__list-item-link--is-selected');
    }
    return focusedElement;
  }
  return null;
}

// Function to handle fallback focus on the menu
handleMenuFocusFallback(): void {
  const { activeElements }: { activeElements: Element[] } = this.getActiveOrSelectedElements();
  const minValue = (activeElements.length <= 2 || activeElements.length === this.cascadeMenus.length)? 1: this.cascadeMenus.length;
  const elementToFocus:any = activeElements[activeElements.length - minValue] || activeElements[0];
  elementToFocus?.focus(); 
  this.showOverlay = false;
}

// Function to handle overlay and cascade removal
handleOverlayAndCascadeRemoval(index: number): void {
  if (!index) {
    this.showOverlay = false;
  } else {
    this.cascadeMenus.pop();  // Remove last cascade menu
  }
  this._cd.detectChanges(); // forcely detect changes after delay 
}

// Function to clean up the menubar display
cleanupMenuDisplay(): void {
  if (this.isShowMenubar) {
    if (!this.cascadeMenus.length) {
      this.ovarlayClickJS();
    } else {
      this.cascadeMenus.pop();// Remove last cascade menu item
    }
  this._cd.detectChanges(); // forcely detect changes after delay 
  }
}

  getSelectedMenu(menu) {
    let eventCategory;
    if (this.data.aceToken?.Role === 'Student') {
      eventCategory = EVENT_CATEGORY.GlobalStudent
    } else {
      eventCategory = EVENT_CATEGORY.GlobalTeacher
    }
    const parentLevel = menu?.parentId?.split('_') || [];
    this.level_1 = this.menuList[parentLevel[0]] ? this.menuList[parentLevel[0]] : menu;
    this.level_2 = this.level_1.children?.find(child => {
      if (child.children) {
        return child.hierarchyLevel === menu.parentId
      } else {
        return child.hierarchyLevel === menu.hierarchyLevel
      }
    });
    this.level_3 = this.level_2?.children?.find(child => {
      if (child.children) {
        return child.hierarchyLevel === menu.parentId
      } else {
        return child.hierarchyLevel === menu.hierarchyLevel
      }
    });
    this.selectedMenu = {
      link_text: menu.name,
      nav_item_level_1: this.level_1 ? this.level_1.name : null,
      nav_item_level_2: this.level_2 ? this.level_2.name : null,
      nav_item_level_3: this.level_3 ? this.level_3.name : null,
      nav_type: 'Main'
    };

    this.gtmService.advanceTracking(eventCategory, GTMEvents.NavClick, this.selectedMenu);
  }
  
  ngAfterContentChecked() {
    let cookieSetting: any = document?.querySelector("#cookiesettings");
    if (cookieSetting) {
      let anchorTag: any = cookieSetting?.querySelector('a');
      if (anchorTag) {
        anchorTag?.setAttribute('data-cc-action', 'preferences');
        anchorTag?.setAttribute('role', 'link');
      }
    }    
  }

  ngOnDestroy(): void {
    this.subActive = false;
    if (this.subscriptionList.length) {
      this.subscriptionList.forEach((subscription) => {
        subscription.unsubscribe();
      });
    }
    // Ensure proper cleanup when the component is destroyed
    this.destroySubscription.next();
    this.destroySubscription.complete();
  }
}
