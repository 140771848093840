import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Data } from '../core/models/data';
import {lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MICROSERVICEJSON } from '../core/models/enum';
import { ApiConstants } from '../core/constant/api.constant';
import { UtilityService } from './utility.service';
@Injectable({
  providedIn: 'root'
})
export class MicroServiceService {
  internetStatus:boolean = false;
  constructor(public http: HttpClient, public data: Data, public utilityService: UtilityService) {
    this.utilityService.checkInternetConnectivity().subscribe(status => {
    this.internetStatus = status;
    })
  }

  initializeApp(): Promise<boolean> {
    if (this.internetStatus) {
      const cdnMicroServiceJsonUrl = `${environment.menuToggleStateUrl}/${MICROSERVICEJSON.fileName}.json?re=${Date.now()}`;
      const assestMicroServiceJsonUrl = this.getAssestUrl(MICROSERVICEJSON.assestFileName, new URL(ApiConstants.siteUrl)?.origin);
      return lastValueFrom(this.http.get<any>(cdnMicroServiceJsonUrl))
        .then(microServiceJson => {
          this.setMicroServiceFlag(microServiceJson);
          return true;
        })
        .catch((error) => {
          // Fallback to local asset if CDN fails
          return lastValueFrom(this.http.get<any>(assestMicroServiceJsonUrl))
            .then(microServiceJson => {
              console.log("CDN error:", error.message);
              this.setMicroServiceFlag(microServiceJson);
              return true;
            })
        }).catch((error) => {
          console.log("both faill CDN and Assest:", error.message);
          return true;
        })
    }
  }

  setMicroServiceFlag(microServiceJson: any) {
    this.data.microServiceFlags = microServiceJson[environment.name.toLowerCase()]?.[0] || {};
  }

  getAssestUrl(filename: string, baseUrl: string) {
    return baseUrl + `${filename}`;
  }
}
